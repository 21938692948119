


export const formatPrice = (value, currency = null, region = 'es-AR', maxDecimals = '0') => {
  return value.toLocaleString(region, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimals
  })
}
