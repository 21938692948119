import Pagination from "../../../../components/Pagination";
import Pill from "./components/Pill";
import InviteMembers from './components/InviteMembers';
import {
  TR,
  TH,
  TD,
  TableHead,
  TableBody,
  Table,
  HorizontalScroll,
  EmptyState,
  TotalMembers,
  Empty,
  MenuButton
} from "./styles";

import services from "../../../../services";
import Dropdown from '../../../../components/DropdownBis'
import EditMemberModal from './components/EditMemberModal'
import { showModal, hideModal } from "../../../../store/actions/modal";
import { useDispatch } from "react-redux";
import moreVerticalIcon from '../../../../assets/icons/more-vertical.svg'
import Button from "../../../../components/Button";
import moment from "moment";
import { dateSpliter } from "../../../../helpers/dates-hanlder";
import { ReactElement, useEffect, useState} from "react";
import CopyToClipboad from "../../../../components/CopyToClipboad";
import constants from "../../../../constants";
import Image from "../../../../components/Image";
import { formatPrice } from "../../../../helpers/price-formatter";
import ExpireMemberModal from "./components/CancelMemberModal";

const Label = ({ countryCode, showCountryCode }) => {
  let country = constants.countries.filter((c) => c.code === countryCode)[0];
  return (
    <>
      <Image src={country?.flag} width="20px" height="15px" alt={""} />{" "}
      {showCountryCode && country.code}
    </>
  );
};

const emptyState = () => {
  const numrows = 20;
  let rows: ReactElement[] = [];

  for (var i = 0; i < numrows; i++) {
    rows.push(
      <TR>
        <TD>
          <Empty />
        </TD>
        <TD>
          <Empty />
        </TD>
        <TD>
          <Empty />
        </TD>
        <TD>
          <Empty />
        </TD>
      </TR>
    );
  }

  return rows;
};

interface Filter {
  [field: string]: (string | number)[];
}

interface ComponentProps {
  groupId?: number;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  data: {
    country: string;
    email: string;
    last_name: string;
    name: string;
    purchased_groups_count: string;
    external_name: string | null;
    status;
    transactions_count;
    billing_cycles;
    remaining_billing_cycles;
    transactions_sum;
    current_amount;
    started_at;
    expire_at;
    type;
    period;
    period_unit;
    currency;
    subscription_id: number | null;
  }[];
  filters: Filter[];
  pagination: {
    currentPage: string;
    from: number;
    lastPage: number;
    perPage: string;
    to: number;
    total: number;
  };
  goToPage: (page: number) => void;
}


const convertPeriodToLabel = (period, period_unit) => {
  if (period_unit === "month") {
    switch (period) {
      case 1:
        return "mensual";
      case 3:
        return "trimestral";
      case 6:
        return "semestral";
      default:
        return "N/A";
    }
  } else if (period_unit === "year" && period === 1) {
    return "anual";
  } else {
    return "periodo no reconocido";
  }
}

const Component = ({
  groupId,
  states,
  data,
  filters,
  pagination,
  goToPage,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | null>(null);

  const getTotalMembersText = (number) => {
    return `${number} ${number > 1 ? "miembros" : "miembro"}`;
  };

  const hasProperty = (property) => {
    return data.filter((item) => !!item[property]).length > 0;
  };

  const paginate = (page) => {
    !!goToPage && goToPage(page);
  };

  return (
    <>
      {pagination?.total > 0 && (
        <div style={{ textAlign: "right" }}>

          <TotalMembers>{getTotalMembersText(pagination?.total)}</TotalMembers>
          <Button
            type="button"
            options={{ type: "link", skin: "purple", size: "md" }}
            onClick={() => {
              groupId
                ? services.group.exportSubscribers(groupId, filters)
                : services.community.exportSubscribers();
            }}
            style={{ marginLeft: "10px" }}
          >
            Exportar listado
          </Button>
        </div>
      )}

      {!states.loading && (!data || data.length <= 0) ? (
        <EmptyState key="empty-state-member-list">
          Aún no tienes suscriptores.
        </EmptyState>
      ) : (
        <HorizontalScroll>
          <Table>
            {states.loading && emptyState()}

            {!states.loading && data?.length > 0 && (
              <>
                <TableHead>
                  <TR>
                    {/*<TH>País</TH>*/}
                    <TH style={{ minWidth: "270px" }}>Info del Suscriptor</TH>
                    {hasProperty("external_name") && (
                      <TH>Plan</TH>
                    )}
                    {hasProperty("status") && <TH>Último estado</TH>}
                    {hasProperty("purchased_groups_count") && (
                      <TH style={{ minWidth: "160px" }}>Grupos comprados</TH>
                    )}

                    <TH style={{ minWidth: "160px" }}>Cobros recibidos</TH>
                    <TH style={{ minWidth: "120px" }}>Total recibido</TH>

                    {hasProperty("started_at") && (
                      <TH style={{ minWidth: "190px" }}>Fecha de suscripción</TH>
                    )}

                
                  </TR>
                </TableHead>

                <TableBody>
                  {data?.map((item, index) => {
                    const memberAt =
                      item.started_at &&
                      dateSpliter(
                        moment
                          .unix(
                            item.started_at.length === 13
                              ? Number(item.started_at) / 1000
                              : item.started_at
                          )
                          .format("YYYY-MM-DDTHH:mm:ss.000Z")
                      );

                    return (
                      <TR
                        key={`member-${item?.name}-${item?.last_name}-${index}`}
                      >


                        <TD style={{ fontWeight: 500,  minWidth: "270px" }}>

                          {!!item.country ? (
                            <Label countryCode={item?.country} showCountryCode={false} />
                          ) : (
                            ""
                          )}

                          {item?.name
                            ? item.last_name
                              ? `${item?.name} ${item?.last_name}`
                              : item.name
                            : item.last_name
                              ? item.last_name
                              : "-"}

                          <br />

                          <CopyToClipboad
                            copy={item?.email}
                            showChildren
                            showCopyIcon={true}
                            successStyle={{
                              position: "absolute",
                              left: "100%",
                              top: "50%",
                              transform: "translate(0%, -50%)",
                            }}
                          >
                            {item?.email}
                          </CopyToClipboad>
                        </TD>

                        {!!item.external_name && (
                          <TD>
                            <span>
                              {item?.external_name}
                            </span>
                            <br />
                            <span style={{ fontSize: "12px" }}>
                              {item.type === "guest" ? "Externo" : !!item.period && !!item.period_unit ? `${item.billing_cycles ? `${item.billing_cycles} cobros x` : ""} $${item?.current_amount || 0} /${convertPeriodToLabel(item.period, item.period_unit)}` : `$${item?.current_amount || 0} /pago único`}
                            </span>

                          </TD>
                        )}

                        {!!item.status && (
                          <TD>
                            <Pill subscriptionState={item.status} />
                          </TD>
                        )}

                        {!!item.purchased_groups_count && (
                          <TD style={{ minWidth: "160px" }}>
                            {item?.purchased_groups_count}
                          </TD>
                        )}

                        <TD
                          style={{ minWidth: "160px" }}
                        >
                          {item?.billing_cycles > 0 && item?.remaining_billing_cycles
                            ? `${item?.billing_cycles - item?.remaining_billing_cycles} de ${item?.billing_cycles}`
                            : `${item?.transactions_count || 0}`
                          }
                        </TD>


                        {(item?.transactions_sum !== undefined) && (
                          <TD
                            style={{ minWidth: "120px" }}
                          ><span style={{ fontWeight: "500" }}>${formatPrice(item?.transactions_sum)}</span> <span style={{ fontSize: "12px" }}>{item?.currency}</span>
                          </TD>
                        )}

                        {!!item.started_at &&
                          memberAt.year !== "Invalid date" && (
                            <TD style={{ minWidth: "190px" }}>
                              {`${memberAt.day.number}-${memberAt.month.name.short}-${memberAt.year}`}
                            </TD>
                          )
                        }
                
                        {(item.type === "guest" || item.type === "recurring") && (
                            <TD style={{ minWidth: "20px", maxWidth: "20px" }}>
                              <Dropdown label="" staticLabel={false} icon={moreVerticalIcon} styles={{
                                wrapper: { border: 0, padding: 0 },
                                list: { minWidth: 100, padding: "8px 0px" },
                                item: { padding: "0px 8px" }
                              }}>
                                <MenuButton
                                  onClick={() => {
                                    if(item && item?.subscription_id)
                                      setSelectedSubscriptionId(item?.subscription_id)
                                      dispatch(showModal('edit-member-modal'))
                                  }}
                                  disabled={item?.status === "expired" || item?.type !== "guest"}
                                  key={`edit-member-${item?.subscription_id}`}
                                  type="button"
                                >
                                  Editar Fecha de Expiración
                                </MenuButton>
                                <MenuButton
                                  onClick={() => {
                                    if(item && item?.subscription_id)
                                      setSelectedSubscriptionId(item?.subscription_id)
                                    dispatch(showModal('expire-member-modal'))
                                  }}
                                  key={`expire-member-${item?.subscription_id}`}
                                  disabled={item?.status === "expired" || item?.status === "cancelled" || item?.status === "non_renewing"}
                                  type="button"
                                >
                                  Dar de baja
                                </MenuButton>
                              </Dropdown>
                            </TD>
                          )}


                        {/*<TD style={{ maxWidth: "40px"}}>
                        <Dropdown label="" staticLabel={false} icon={moreVerticalIcon} styles={{
                          wrapper: { border: 0, padding: 0 },
                          list: { minWidth: 100, padding: "8px 0px" },
                          item: { padding: "0px 8px" }
                        }}>
                          <MenuButton
                            onClick={() => console.log('selected')}
                            key={`edit-member-${item.email}`}
                            type="button"
                          >
                            Editar
                          </MenuButton>
                          <MenuButton
                            onClick={() => console.log('selected')}
                            key={`edit-member-2-${item.email}`}
                            type="button"
                          >
                            Editar
                          </MenuButton>
                        </Dropdown>
                      </TD>*/}
                      </TR>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </HorizontalScroll>
      )}

      {!states.loading && data?.length > 0 && (
        <Pagination
          {...pagination}
          states={states}
          onClick={(page) => paginate(page)}
        />
      )}

      <EditMemberModal subscriptionId={selectedSubscriptionId} />
      <ExpireMemberModal subscriptionId={selectedSubscriptionId} />
      
    </>
  );
};

export default Component;
