import styled from "styled-components";

export const Icon = styled("i")<{ size; color?; icon }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: block;

  &:before {
    content: "";
    display: block;
    ${({ color, icon }) =>
      !!color
        ? `background-color: ${color};
      mask: url(${icon});
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;`
        : `background-image: url(${icon});
        background-size: cover;
    background-position: center;
      `};
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`;
