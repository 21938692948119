import action from "../helpers/rest-client";

export interface ErrorPlan extends Error {
  response: {
    status: number,
    statusText: string,
  }
}

class Plan {
  public async new(payload) {
    return action
      .Post({
        url: `/api/plan`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error: ErrorPlan) => {
        return { error };
      });
  }

  public async update(payload) {
    const { idPlan } = payload

    return action
      .Put({
        url: `/api/plan/${idPlan}`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error: ErrorPlan) => {
        return { error };
      });
  }

  public async delete(payload) {
    const { idPlan } = payload
    return action
      .Put({
        url: `/api/plan/${idPlan}`,
        body: { status: 'deleted' }
      })
      .then((response) => {
        return { response };
      })
      .catch((error: ErrorPlan) => {
        return { error };
      });
  }

  public async getOne(payload) {
    return action
      .Get({
        url: `/api/plan/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error: ErrorPlan) => {
        return { error };
      });
  }

  public async getAll(payload) {
    return action
      .Get({
        url: `/api/plan/${payload.idGroup}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error: ErrorPlan) => {
        return { error };
      });
  }
}

const plan = new Plan();
export default plan;
