import { useMemo } from 'react'
import {
  Row,
  Card,
  Plan,
  Price,
  Frecuency,
  Discount,
  Description,
  Status,
  Payments,
  Button,
  DiscountAmount,
  VerticalSeparator
} from './styles'
import Image from '../../../../../../../components/Image'
import Dropdown from '../../../../../../../components/DropdownBis'
import flagArgentina from '../../../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../../../constants/countries/flags/flag-UE.png'
import moreVerticalIcon from '../../../../../../../assets/icons/more-vertical.svg'
import { PlanDto } from '../../../../../../../types/plan.dto'
import { formatPrice } from '../../../../../../../helpers/price-formatter'
import { DiscountDto } from '../../../../../../../types'
import dateHelpers from "../../../../../../../helpers/dates-hanlder";
import { selectedDiscount } from '../../../../../../../store/actions/discount'
import { useDispatch } from 'react-redux'

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

const status = {
  expired: 'Oculto',
  inactive: 'Oculto',
  active: 'Visible',
  deleted: 'Dado de baja'
}

const periodUnitsTranslations = {
  week: {
    singular: 'semana',
    plural: 'semanas'
  },
  month: {
    singular: 'mes',
    plural: 'meses'
  },
  year: {
    singular: 'año',
    plural: 'años'
  }
}

interface ComponentProps {
  plan: PlanDto
  onSelect
  onPause
  onActive
  onDelete
  onHighlight
  onUnhighlight
  addPromotion,
  editPromotion
}

const Component = ({
  plan,
  onSelect,
  onPause,
  onActive,
  onDelete,
  onHighlight,
  onUnhighlight,
  addPromotion,
  editPromotion
}: ComponentProps) => {
  const dispatch = useDispatch()
  const planCurrency = useMemo(() => (currencies.find(currency => currency?.iso === plan.currency)), [plan])

  const planPeriod = useMemo(() => {
    if (plan) {
      if (plan.type === 'subscription') {
        const isPlural = plan?.period && plan?.period > 1;
        const period = plan?.period_unit && periodUnitsTranslations[plan?.period_unit]
        return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
      } else {
        return "Pago único"
      }
    }
  }, [plan])

  const planCicles = useMemo(() => {
    if (plan) {
      if (plan.type === 'subscription') {
        return plan?.billing_cycles || 'Indefinidos'
      } else {
        return "1"
      }
    }

  }, [plan])

  const discount: DiscountDto | undefined = useMemo(() => (
    plan?.discounts?.find(item => item.status === 'active' && (!item.valid_till || (item.valid_till && new Date(item.valid_till)) >= new Date()))
  ), [plan])

  return (
    <Card featured={!!plan.featured}>
      <Plan featured={!!plan.featured}>
        <Row>
          <Description><div style={{minWidth: 100}}>{plan?.external_name}</div></Description>
          <Status status={plan?.status}>{status[plan?.status]}</Status>
          <Dropdown label="" staticLabel={false} icon={moreVerticalIcon} styles={{
            wrapper: { border: 0, padding: 0 },
            list: { minWidth: 100, padding: "8px 0px" },
            item: { padding: "0px 8px" }
          }}>
            <Button
              onClick={() => onSelect(plan)}
              key={`edit-plan-${plan.id}`}
              type="button"
            >
              Editar
            </Button>
            {!!discount ? (
              <Button
                onClick={() => {

                  onSelect(plan)
                  dispatch(selectedDiscount(discount))
                  editPromotion(true)
                }}
                key={`edit-promotion-plan-${plan.id}`}
                type="button"
              >
                Editar descuento
              </Button>
            ) : (
              <Button
                onClick={() => {
                  onSelect(plan);
                  addPromotion(true);
                }}
                key={`create-promotion-plan-${plan.id}`}
                type='button'
              >
                Crear descuento
              </Button>

            )}
            <Button
              onClick={() => plan.featured ? onUnhighlight(plan) : onHighlight(plan)}
              key={`feature-plan-${plan.id}`}
              type="button"
            >
              {plan.featured ? 'Deshacer destaque' : 'Destacar'}
            </Button>
            <Button
              onClick={() => {
                if (plan.status === 'expired') {
                  onActive(plan)
                } else {
                  onPause(plan)
                }
              }}
              key={`pause-plan-${plan.id}`}
              type="button"
            >
              {plan.status === 'expired' ? 'Activar' : 'Ocultar'}
            </Button>
            <Button
              onClick={() => onDelete(plan)}
              key={`delete-plan-${plan.id}`}
              //disabled={plan.subscriptions_count > 0}
              type="button"
            >
              Eliminar
            </Button>
          </Dropdown>
        </Row>
        <Row>

          <Image src={planCurrency?.flag} width="28px" height="20px" preload={false} alt="" />

          <Description>
            <Price>
              {`$${formatPrice(plan.price)} ${plan.currency} `}
            </Price>

            <Frecuency>
              / {planPeriod}
            </Frecuency>
          </Description>

        </Row>

        <Row>
          <Payments>Cantidad de cobros: {planCicles}</Payments>
        </Row>
      </Plan>

      <Discount>
        {!!discount ? (
          <>
            <DiscountAmount>${formatPrice(discount?.amount)} OFF</DiscountAmount>

            <VerticalSeparator />

            <span>
              Descuento activo {discount?.valid_till ? (
                <>hasta el <strong>{dateHelpers.getDateInLocalTimeZone(discount?.valid_till)}</strong></>
              ) : (
                <><strong>sin fecha de expiración </strong>{/*discount.duration_type === "forever" ? "(En todos los cobros)" : (discount.duration_type === "limited_period" ? `(En los 1eros ${discount.period} cobros)` : "(En el 1er cobro)")*/}</>
              )}
            </span>
          </>
        ) : (
          <span>No hay descuentos activos</span>
        )}
      </Discount>
    </Card>
  )
}

export default Component
