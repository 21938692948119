import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import SignUp from "../../components/SignUpForm";
import { signUp } from "../../../store/actions/auth";
import { SubscriptionContext } from "../../../providers/Subscription";
import { subscriptionStates } from "../../../store/types/user/subscription";
import { useContext, useState, useEffect } from "react";
import ReactGA from 'react-ga4';



const Component = ({ userInformation }) => {
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);

  const { subscriptionState, groupUrl, groupType} = useContext(SubscriptionContext);
  const dispatch = useDispatch();


  const onFormSubmit = ({ values }) => {
    dispatch(signUp(values));
  };

  if (!!userInformation) {
    console.log("userInformation", userInformation)
    console.log("subscriptionState", subscriptionState)
    console.log("groupType", groupType)
    if(!!userInformation && subscriptionState === subscriptionStates.INPROGRESS && groupType === "membership"){
      ReactGA.event({
        action: 'subscriber_sign_up', // Event name set to "subscriber_sign_up"
        category: 'membership', // Additional par
        label: 'Suscriptor clickea en registrarse para una membresia',
      }, {email: userInformation?.email, id: userInformation.id});
    }

    if (subscriptionState === subscriptionStates.INPROGRESS) {
      // return <Redirect to={groupUrl} />;
    }

    // return <Redirect to="/profile/subscriptions" />;
  }

  return (
    <SignUp
      usertType="subscriber"
      onFormSubmit={onFormSubmit}
      formSubmmited={formSubmmited}
      setFormSubmmited={setFormSubmmited}
    />
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  return {
    userInformation,
  };
};

export default connect(state)(Component);
