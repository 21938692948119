import { SectionTitle } from "./styles";
import { Actions } from "../../../../components/styles";
import Grid from "../../../../../../components/Grid";
import CallToAction from "../../../../components/CallToAction";

import newDiffusion from "../../../../../../assets/community/send-diffusion-purple.svg";
import newResourse from "../../../../../../assets/community/new-resourse-purple.svg";
import freeEvent from "../../../../../../assets/community/free-event-purple.svg";
import { connect } from "react-redux";
import { Group } from "../../../../../../types";

interface ComponentProps {
  community: Group;
}

const Component = ({ community }: ComponentProps) => {
  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <SectionTitle>aaaaa</SectionTitle>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Actions>
            <CallToAction
              title="Crear Lead Magnet"
              description="Comparte un contenido gratuito para que se sumen más personas a tu comunidad."
              link={`/dashboard/community/${community?.id}/posts/new`}
              options={{
                preffix: newResourse,
                color: "rgba(219, 243, 244, 1)",
                size: "xsmall",
                icon: false,
              }}
            />

            {/* <Grid.Col sm={4}>
              <CallToAction
                title="Enviar una difusión"
                description="Aumenta la llegada de tu contenido enviándolo a todos los miembros de tu comunidad"
                link="/dashboard/diffusion"
                options={{
                  preffix: newDiffusion,
                  color: "rgba(253, 236, 243, 1)",
                  size: "xsmall",
                  icon: false,
                }}
              />
            </Grid.Col> */}

            {/* <CallToAction
              title="Crear evento gratuito"
              description="Crea un evento gratuito para sumar más personas a tu comunidad"
              link="/dashboard/community/events/new"
              options={{
                preffix: freeEvent,
                color: "rgba(253, 236, 243, 1)",
                size: "xsmall",
                icon: false,
              }}
            /> */}
          </Actions>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ communityStore }) => {
  const { data: community } = communityStore.community;
  return { community };
};

export default connect(state)(Component);
