import Card from '../Card'
import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  DroppableProps,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult
} from "react-beautiful-dnd";

import Sidebar from "../../../../../components/Sidebar";
import Button from "../../../../../components/Button";
import Snackbar from "../../../../../components/Snackbar";

import { connect, useDispatch } from 'react-redux'
import { getAllCategories, reorderCategories } from '../../../../../store/actions/category'

import {
  Description,
  List,
  ListItem,
  Preview
} from './styles';

import { getGroupById } from '../../../../../store/actions/group'
import { CategoryDto } from '../../../../../types/category';

interface ComponentProps {
  group,
  groupStates,
  allCategories,
  allCategoriesState,
  reorderedCategories,
  reorderedCategoriesState,
  onClose,
  isVisible,
  setShowDeleteCategorySidebar,
  setShowEditCategorySidebar,
  setShowCreateCategorySidebar,
  showCreateCategorySidebar,
  setSelectedCategoryToBeDeleted,
  setSelectedCategorToEdit
}

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const Component = ({
  group,
  groupStates,
  allCategories,
  allCategoriesState,
  reorderedCategories,
  reorderedCategoriesState,
  onClose,
  isVisible,
  setShowDeleteCategorySidebar,
  setShowEditCategorySidebar,
  setShowCreateCategorySidebar,
  showCreateCategorySidebar,
  setSelectedCategoryToBeDeleted,
  setSelectedCategorToEdit
}: ComponentProps) => {
  const dispatch = useDispatch()
  const [categories, setCategories] = useState<CategoryDto[]>([]);

  useEffect(() => {
    if (!!group) {
      dispatch(getAllCategories({ group_id: group?.id, posts_per_category: 6, sort_by: 'category_order:asc' }))
    }
  }, [group])

  const reorder = () => {
    dispatch(reorderCategories(categories));
  };

  const onDragEnd = (payload: DropResult) => {
    const { source, destination } = payload;

    if (!destination) {
      return;
    }

    const result: CategoryDto[] = Array.from(categories);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    const newOrder = result.map((item: CategoryDto, index) => {
      return {
        ...item,
        category_order: index,
      };
    });

    setCategories(newOrder);
  };

  useEffect(() => {
    const orderedCategories = allCategories
      ?.sort(function (a, b) {
        return a.category_order - b.category_order;
      })

    setCategories(orderedCategories || []);
  }, [allCategories]);

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose(false);
      }}
      width="463px"
      title="Administrar categorías"
    >
      <Preview>
        <Description>Mueve y arrastra los bloques para ordenar las categorias, luego toca en "Guardar Orden"</Description>

        <div style={{ height: 30 }} />


        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          {/* <Button
            disabled={allCategoriesState.loading}
            options={{
              type: "outline",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => onClose()}
          >
            Cancelar
          </Button> */}

          <Button
            disabled={allCategoriesState.loading}
            loading={allCategoriesState.loading}
            options={{
              type: "filled",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => reorder()}
          >
            Guardar Orden
          </Button>
        </div>

        <div style={{ height: 30 }} />

        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId={'category-drag-and-drop'}>
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <List key={'category-drag-and-drop'} {...provided.droppableProps} ref={provided.innerRef}>

                {categories?.filter(cat => String(cat?.id) !== "without_category").map((category, index) => (
                  <>
                    <Draggable
                      key={"category-" + category.id}
                      draggableId={"category-" + category.id}
                      index={index}
                      isDragDisabled={String(category?.id) === "without_category"}
                    >
                      {(
                        providedDraggable: DraggableProvided,
                        snapshotDraggable: DraggableStateSnapshot
                      ) => (
                        <ListItem
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          ref={providedDraggable.innerRef}
                          style={{
                            ...providedDraggable?.draggableProps?.style,
                            left: 50
                            // Add any other styles as needed
                          }}
                        >
                          <Card category={category} setSelectedCategoryToBeDeleted={setSelectedCategoryToBeDeleted} setSelectedCategorToEdit={setSelectedCategorToEdit} />
                        </ListItem>

                      )}
                    </Draggable>
                  </>
                ))}

                {provided.placeholder}

                <ListItem>
                  <Card showActions={false} category={categories.find(cat => String(cat?.id) === "without_category")} setSelectedCategoryToBeDeleted={setSelectedCategoryToBeDeleted} setSelectedCategorToEdit={setSelectedCategorToEdit} />
                </ListItem>
              </List>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        <div style={{ height: 30 }} />
      </Preview>
    </Sidebar>

    <Snackbar
      visible={reorderedCategoriesState.success || !!reorderedCategoriesState.error}
      options={{
        time: 2000,
        type: reorderedCategoriesState.success ? "success" : "error",
      }}
    >
      {reorderedCategoriesState.success
        ? "Categorías reorganizadas."
        : "Error al reorganizar las categorías."}
    </Snackbar>
  </>
}

const state = ({ groupStore, categoryStore }) => {
  const { data: allCategories, states: allCategoriesState } =
    categoryStore?.allCategories;
  const { data: reorderedCategories, states: reorderedCategoriesState } =
    categoryStore?.reorderCategories;

  const { data: group, states: groupStates } =
    groupStore.group;

  return {
    group,
    groupStates,
    allCategories,
    allCategoriesState,
    reorderedCategories,
    reorderedCategoriesState
  };
};

export default connect(state)(Component);