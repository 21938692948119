import React, { useMemo } from "react";
import arrowIcon from "../../../../../../../assets/icons/arrow_forward_up_right.svg";
import closeIcon from "../../../../../../../assets/icons/close_circled.svg";
import WhatsappIcon from "../../../../../../../assets/icons/whatsapp.svg";
import TelegramIcon from "../../../../../../../assets/icons/telegram.svg";
import Icon from "../../../../../../../components/Icon";
import { Center } from "../../../../../../../components/StyledComponents";
import { connect } from "react-redux";
import {CardBackground, CardHeader, HeaderText, Close, Description, Button, Wrapper} from './styles'
import SpinnerButton from "../../../../../../../components/SpinnerButton";

type ChatBannerCoreActionProps = {
  user;
  group;
  groupStates;
  subscriberTelegramUrl,
  subscriberTelegramUrlStates,
  expertTelegramUrl,
  expertTelegramUrlStates,
  onClose: () => void;
};

const platformsSettings = {
  whatsapp: {
    icon: WhatsappIcon,
    name: "Whatsapp",
    color: "#24d366",
    primaryColor: "#25d366",
    secondaryColor: "#DCF8C6",
    link: "https://www.whatsapp.com/"
  },
  telegram: {
    icon: TelegramIcon,
    name: "Telegram",
    color: "#039be5",
    primaryColor: "#229ED9",
    secondaryColor: "#DBF3F4",
    link: "http://onelink.to/84gpfk"
  },
};

const ChatBannerCoreAction = ({ user, group, expertTelegramUrl, subscriberTelegramUrl, 
  subscriberTelegramUrlStates, expertTelegramUrlStates, groupStates, onClose}: ChatBannerCoreActionProps) => {

  const isOwnGroup = useMemo(() => {
    return group?.expert?.id === user?.expert?.id
  }, [group, user])

  const platform = group?.telegram_groups[0]?.platform

  return (
    <Wrapper>
     <CardBackground primaryColor={platformsSettings[platform]?.primaryColor}>
        <CardHeader>
          <HeaderText>¡No te lo pierdas!</HeaderText>
          <Close style={{ marginRight: 5 }} onClick={onClose}>
            <Icon  icon={closeIcon} size={"22px"} color={"black"} />
          </Close>
          
        </CardHeader>

        <Description>Ingresa a nuestra Comunidad en 
          <Center vertical="center" horizontal="left" gap={3}>
            {platformsSettings[platform]?.name} 
            <div style={{alignSelf: "flex-start", paddingTop: "4px"}}>
              <Icon icon={platformsSettings[platform]?.icon} size={"12px"} color={"white"} />
            </div>
          </Center>
        </Description>

        <Button
          href={isOwnGroup ? expertTelegramUrl ||  "" : subscriberTelegramUrl || ""}
          target="_blank"
        >
          <SpinnerButton 
            isLoading={!!subscriberTelegramUrlStates?.loading || !!expertTelegramUrlStates?.loading 
            || !!groupStates.loading }
          >
            Entrar ya
            <Icon icon={arrowIcon} size={"16px"} color={"white"} />
          </SpinnerButton>

        </Button>
      </CardBackground>
  </Wrapper>
  );
};


const state = ({ groupStore, userStore, preferencesStore }) => {
  const { data: subscriberTelegramUrl, states: subscriberTelegramUrlStates } = groupStore?.subscriberTelegramGroup;
  const { data: expertTelegramUrl, states: expertTelegramUrlStates } = groupStore?.expertTelegramGroup;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: user, states: userStates } = userStore.information;

  return {
    user,
    userStates,
    group,
    groupStates,
    subscriberTelegramUrl,
    subscriberTelegramUrlStates,
    expertTelegramUrl,
    expertTelegramUrlStates,
  };
};

export default connect(state)(ChatBannerCoreAction);

