import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { deleteWelcomeContentPost, resetDeleteWelcomeContentPost } from "../../../../../store/actions/welcome-content";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";
import { DeleteWelcomeContentPostOutDto } from "../../../../../types/welcome-section";

interface ComponentProps {
  welcomeContent: DeleteWelcomeContentPostOutDto;
  deleteWelcomeContentPostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({welcomeContent, deleteWelcomeContentPostStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const handleDeleteWcPost = () => {
    console.log('welcomeContent', welcomeContent)
    dispatch(deleteWelcomeContentPost(welcomeContent));
  };

  const onModalClose = () => {
    if (deleteWelcomeContentPostStates.success) {
      dispatch(resetDeleteWelcomeContentPost());

      setTimeout(() => {
        window.history.back();
      }, 500);
    }
  };

  return (
    <Modal name="modal-delete-welcome-content-post" width="650" onClose={() => onModalClose()}>
      {deleteWelcomeContentPostStates.success ? (
        <>
          <div style={{ textAlign: "center" }}>
            <Image src={remove} width="102px" height="117px" alt={remove} />
          </div>
          <Text>
            <strong style={{ textAlign: "center", display: "block" }}>
              Contenido eliminado satisfactoriamente
            </strong>
          </Text>
        </>
      ) : (
        <>
          <Title>Vas a eliminar un contenido</Title>

          <Text>
            Una vez eliminado no podrás recuperarlo y 
            quienes tenían acceso al mismo ya no podrán verlo.
          </Text>

          <AlignEnd>
            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
              }}
              style={{ marginRight: "16px" }}
              onClick={() => dispatch(hideModal())}
            >
              Cancelar
            </Button>
            <Submit
              form=""
              isSubmmiting={deleteWelcomeContentPostStates.loading}
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              onClick={() => handleDeleteWcPost()}
            >
              Eliminar articulo
            </Submit>
          </AlignEnd>
        </>
      )}
    </Modal>
  );
};

const state = ({ welcomeContentStore }) => {
  const { data: welcomeContent } = welcomeContentStore.welcomeContentPost;
  const { states: deleteWelcomeContentPostStates } = welcomeContentStore.deleteWelcomeContentPost;
  return {
    welcomeContent,
    deleteWelcomeContentPostStates,
  };
};

export default connect(state)(Component);
