import {
  GroupCard,
  GroupCardHeader,
  Information,
  GroupName,
  Date,
  Price,
  GroupCardBody,
  StateText,
  LinksOptions,
  Link,
  HorizontalSeparator,
  ExpertName
} from "./styles";
import { Subscription } from "../../../../../types";
import dateHelpers from "../../../../../helpers/dates-hanlder"
import Pill from "../Pill"
import { GroupCardAvatar } from "../../../../../components/ExpertGroupCard/styles"

interface PropsInterface {
  subscription: Subscription;
  onSelect;
}

// const statusLabel = {
//   "active": "Activa",
//   "cancelled": "Cancelada",
//   "expired": "Expirada",
//   "non_renewing": "Proxima a vencer",
//   "pending_invite": "Pendiente de aceptación"
// }

const getSubscriptionMessage = (subscription) => {
  switch (subscription?.status) {
    case "cancelled":
      return subscription?.cancelled_at
        ? subscription?.cancel_schedule_create_at
          ? `Diste de baja tu suscripción el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.cancelled_at, "DD/MM/YYYY")}`
          : `Tu suscripción se canceló el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.cancelled_at, "DD/MM/YYYY")} porque tu pago fue rechazado.`
        : "Tu suscripción fue cancelada"
    case "non_renewing":
      return `No se realizarán más cargos por esta suscripción. ${subscription?.next_billing_at && `Tienes acceso al contenido hasta el ${dateHelpers.inputDate(subscription?.next_billing_at, "DD/MM/YYYY")}`}`
    case "active":
      return subscription?.next_billing_at ? `Tu próxima fecha de facturación es el ${dateHelpers.inputDate(subscription?.next_billing_at, "DD/MM/YYYY")}.` : ""
    case "paid":
      return subscription?.started_at ? `Tu pago fue realizado el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.started_at, "DD/MM/YYYY")}` : "Has realizado el pago correctamente"
    case "expired":
      return `Tu suscripción de invitado expiró ${subscription?.cancelled_at ? `el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.cancelled_at, "DD/MM/YYYY")}` : ""}`
    default:
      return "";
  }
};

const getGroupTypeText = (type) => {
  switch (type) {
    case "membership":
      return "Membresia";
    case "experience":
      return "Taller"
    case "free_experience":
      return "Taller gratuito"
    default:
      return "Grupo"
  }
}

const getCtaText = (status) => {
  switch (status) {
    case "active":
      return "Cancelar suscripción";
    case "cancelled":
      return "Re-Suscribirme"
    case "non_renewing":
      return "Reactivar suscripción";
    default:
      return ""
  }
}

const getPlanText = (subscription) => {
  const planType = subscription?.plan?.type;
  const hasPeriod = !!subscription?.plan?.period && !!subscription?.plan?.period_unit;
  const billingCycles = subscription?.plan?.billing_cycles;
  const currentAmount = subscription?.current_amount || 0;
  const currency = subscription?.plan?.currency;

  let subscriptionDetails;

  if (planType === "guest") {
    subscriptionDetails = "Invitado";
  } else if (hasPeriod) {
    const billingCyclesText = billingCycles ? `${billingCycles} cobros x ` : "";
    const periodLabel = convertPeriodToLabel(subscription?.plan?.period, subscription?.plan?.period_unit);
    subscriptionDetails = `${billingCyclesText} ${currency} ${currentAmount} /${periodLabel}`;
  } else {
    subscriptionDetails = `${currency} ${currentAmount} /pago único`;
  }

  return subscriptionDetails;
}

const convertPeriodToLabel = (period, period_unit) => {
  if (period_unit === "month") {
    switch (period) {
      case 1:
        return "mensual";
      case 3:
        return "trimestral";
      case 6:
        return "semestral";
      default:
        return "N/A";
    }
  } else if (period_unit === "year" && period === 1) {
    return "anual";
  } else {
    return "periodo no reconocido";
  }
}

const Component = ({ onSelect, subscription }: PropsInterface) => {
  return (
    <GroupCard>
      <GroupCardHeader>
        <GroupCardAvatar
          src={subscription?.group?.expert?.profile_pic?.url}
          alt="Profile Logo"
          width="50px"
          height="50px"
        />

        <Information>
          <ExpertName> {getGroupTypeText(subscription?.group?.group_type)} de {subscription?.group?.expert?.username}</ExpertName>
          <GroupName>"{subscription?.group?.group_name}"</GroupName>


        </Information>
      </GroupCardHeader>
      <HorizontalSeparator />

      <GroupCardBody>
        <Pill subscriptionState={subscription?.status} />
        <StateText>
          {getSubscriptionMessage(subscription)}
        </StateText>

        <Price>
          <span>Plan:</span> {" "} {getPlanText(subscription)}
        </Price>

        <Date>
          <span>Fecha de inicio: {dateHelpers.fromUtcSecondsToDateStr(subscription?.started_at)}</span>
        </Date>

          <LinksOptions>
            {/* <Link
            href={""}
            options={{
              skin: "purple",
              size: "md",
              type: "outline",
            }}
          >
            Cambiar método de pago
          </Link> */}
            <Link
              href='#'
              onClick={() => onSelect(subscription)}
              options={{
                skin: "purple",
                size: "lg",
                type: `${subscription?.status === "active" ? "link" : "filled"}`,
              }}
            >
              {getCtaText(subscription?.status)}
            </Link>
          </LinksOptions>
      </GroupCardBody>
    </GroupCard>
  );
};

export default Component;
