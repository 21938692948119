import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "../../../../../components/Loader";
import { Center } from "../../../../../components/StyledComponents";
import Modal from "../../../../../components/Modal"
import Button from "../../../../../components/Button"
import dateHelpers from '../../../../../helpers/dates-hanlder'
import { Subscription, User } from "../../../../../types";
import { acceptInvitation } from "../../../../../store/actions/invitation";
import { hideModal } from "../../../../../store/actions/modal";

import {
  useLocation,
} from "react-router-dom";
import { splitCell } from "@remirror/pm/tables";

interface ComponentProps {
  subscriptions: Subscription[];
  subscriptionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  userInformation: User;
  userInformationStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  acceptedInvitation
  acceptInvitationStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  subscriptions,
  subscriptionsStates,
  userInformation,
  userInformationStates,
  acceptedInvitation,
  acceptInvitationStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [subscriptionInvited, setSubscriptionInvited] = useState<Subscription>()
  const location = useLocation()

  const groupType = useMemo(() => {
    const groupTypes = {
      "experience": "taller",
      "free_experience": "taller gratuito",
      "membership": "membresía"
    }
    return subscriptionInvited?.group ? groupTypes[subscriptionInvited?.group?.group_type] : ""
  }, [subscriptionInvited])

  const groupName = useMemo(() => {
    return subscriptionInvited?.group?.group_name || ""
  }, [subscriptionInvited])



  const expertName = useMemo(() => {
    return subscriptionInvited?.group?.expert?.username || ""
  }, [subscriptionInvited])

  const userName = useMemo(() => {
    return userInformation?.name || ""
  }, [userInformation])

  const untilDate = useMemo(() => {    
    let date;
    if(subscriptionInvited?.expire_at && subscriptionInvited?.expire_at !== "0" ){
      const splittedDate = dateHelpers.dateSpliter(dateHelpers.timestampToDate(Number(subscriptionInvited?.expire_at)))
      date = `${splittedDate?.day?.number} de ${splittedDate?.month?.name?.large} del ${splittedDate.year}`
    } else {
      date = 'Indefinido'
    }
    
    return date !== "Indefinido" ? `hasta el ${date}.` : `por tiempo indefinido`;
  }, [subscriptionInvited])


  useEffect(() => {
    if (!!subscriptions) {

      const regex = /\/group\/([^\/]+)/;
      const result = location.pathname.match(regex);
      const slug = result && result[1]
    
      let pendingInvite;
      if(!!slug){
        pendingInvite = subscriptions?.find((subscription) => subscription.status === 'pending_invite' && (subscription?.group?.slug === slug || subscription?.group?.id === Number(slug)))
      } else
        pendingInvite = subscriptions?.find((subscription) => subscription.status === 'pending_invite')

      if (!!pendingInvite) {
        setSubscriptionInvited(pendingInvite)
      }
    }
  }, [subscriptions]);

  useEffect(() => {
    if (acceptInvitationStates.success) {
      setTimeout(() => {
        dispatch(hideModal())
        window.location.href = `/group/${subscriptionInvited?.group?.slug}/survey`
      }, 500)
    }
  }, [acceptInvitationStates]);

  return (
    <Modal
      name="pending-invitation"
      width="650"
      onClose={() => { }}
    >
      <p style={{ textAlign: "center", margin: 0 }}>Hola {userName} 👋​,</p>
      <div style={{ height: 14 }} />
      <p style={{ textAlign: "center", margin: 0 }}><b>{expertName}</b> te ha invitado a sumarte a su {groupType}:<b>"{groupName}"</b> {untilDate}✨</p>
      <div style={{ height: 14 }} />
      {/*<p style={{ textAlign: "center", margin: 0 }}><i>”¿Listo para comenzar una nueva aventura? Únete a {groupType === "taller" ? `nuestro exclusivo ${groupType}` : `nuestra exclusiva ${groupType}`} y descubre un viaje transformador hacia la mejor versión de ti mismo. ¡Desbloquea contenido inspirador, eventos emocionantes y una comunidad increíble! 💪🚀 ¡Te esperamos!”</i></p>*/}
      {/*<div style={{ height: 28 }} />*/}
      <p style={{ textAlign: "center", margin: 0 }}>Para acceder a todo el contenido toca en Aceptar Invitación:</p>
      <div style={{ height: 28 }} />
      <Center vertical="center" horizontal="center" gap={5}>
        {/*<Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
        >
          Cancelar
        </Button>*/}
        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          disabled={!subscriptionInvited?.reference_id || acceptInvitationStates?.loading}
          onClick={() => !!subscriptionInvited?.reference_id && dispatch(acceptInvitation({ reference_id: subscriptionInvited?.reference_id }))}
        >
          <Center vertical="center" horizontal="center" gap={5}>
            {acceptInvitationStates?.loading ? (
              <Spinner color="white" />
            ) : (
              "Aceptar invitación"
            )}
          </Center>
        </Button>
      </Center>
    </Modal >
  )
}


const state = ({ userStore, invitationStore }) => {
  const { data: acceptedInvitation, states: acceptInvitationStates } = invitationStore.acceptInvitation;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
    acceptedInvitation,
    acceptInvitationStates
  };
};

export default connect(state)(Component);