/* eslint-disable react-hooks/exhaustive-deps */
import { AuthContext } from "../../../providers/Auth";
import { connect, useDispatch } from "react-redux";
import { getUserSubscriptions } from "../../../store/actions/user";
import ManageSubscriptionsCard from "./components/ManageSubscriptionsCard";
import { useContext, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/LogoLoader";
import Grid from "../../../components/Grid";
import { Filters, Text } from "./styles";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import { User, Subscription } from "../../../types";
import ConfirmCancellationModal from "./components/ConfirmCancellationModal";
import { showModal } from "../../../store/actions/modal";
import constants from "../../../constants";
import { Formik } from "formik";
import ReactSelect from "../../../components/FormikForm/ReactSelect";
import Submit from "../../../components/FormikForm/Submit";
import {
  initialValues,
  schema,
} from "../../../constants/forms/subscription-admin/subscriptions-admin-filters";

const Groups = ({ userInformation, groups }) => {
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)

  const { whatsapp } = constants;

  const dispatch = useDispatch()

  const itemsPerRow = {
    isMobile: 1,
    isTabletLandscape: 3,
    isTabletPortrait: 2,
    isDesktop: 3,
  };

  const { isTabletPortrait, isTabletLandscape, isDesktop } = useWindowSize();
  let windowSize = "isMobile";
  windowSize = isTabletPortrait ? "isTabletPortrait" : windowSize;
  windowSize = isTabletLandscape ? "isTabletLandscape" : windowSize;
  windowSize = isDesktop ? "isDesktop" : windowSize;

  const responsiveItemsPerRow = itemsPerRow[windowSize];
  const totalRows = Math.ceil(groups.length / responsiveItemsPerRow);
  const rows: any[] = [];


  for (let i = 0; i < totalRows; i++) {
    rows.push(
      groups.slice(
        i * responsiveItemsPerRow,
        i * responsiveItemsPerRow + responsiveItemsPerRow
      )
    );
  }

  const onSelect = (subscription) => {
    setSelectedSubscription(subscription)

    switch (subscription?.status) {
      case "active":
        dispatch(showModal('confirm-cancellation-modal'))
        break;
      case "non_renewing":
        window.open(`${constants.whatsapp.url}?phone=${whatsapp.number}&text=${whatsapp.reactivate_subscription.replace("{name}", userInformation?.name).replace("{email}", userInformation?.email).replace("{group}", subscription?.group?.group_name).replace("{subscription_id}", subscription?.id)}`, '_blank');
        break;
      case "cancelled":
        window.open(`${process.env.REACT_APP_SITE_BASE_URL}/group/${subscription?.group?.slug}`, '_blank');
        break;
      default:
        break;
    }

  }

  return (
    <>
      {rows.map((row, index1) => (
        <Grid.Row key={`row-groups-${index1}`}>
          {row.map((subscription, index2) => (
            <Grid.Col sm={12} lg={6} key={`subscription-${index1}-${index2}`}>
              <ManageSubscriptionsCard
                onSelect={onSelect}
                subscription={subscription}
                key={`manage-subscription-${index1}-${index2}`}
              />
            </Grid.Col>
          ))}
        </Grid.Row>
      ))}
      <ConfirmCancellationModal subscription={selectedSubscription} />
    </>
  );
};

interface Filter {
  [field: string]: (string | number)[];
}

interface ComponentProps {
  setActiveTab?;
  subscriptions: Subscription[];
  subscriptionsStates;
  userInformation: User;
}

const Component = ({ userInformation, subscriptionsStates, subscriptions, setActiveTab }: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  // const [forceRerender, setForceRerender] = useState(false);
  const [statusesFilter, setStatusesFilter] = useState<string[]>([]);

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        actions: []
      },
      page: {
        title: "Administra tus suscripciones",
        description: "Aquí puedes ver y administrar tus suscripciones recurrentes adheridas al débito automático.",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout]
  );

  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveTab("manage-subscriptions");
    setLayout(layoutData);
  }, []);

  useEffect(() => {
    if (!!user) {
      dispatch(getUserSubscriptions({
        filters: {
          status_in: "active,non_renewing,cancelled",
        }
      }));
    }
  }, [user]);

  useEffect(() => {
    if(statusesFilter.length <=0){
      setStatusesFilter(['active', 'cancelled', 'non_renewing'])
    }
  }, [statusesFilter]);

  if (subscriptionsStates.loading) {
    return (<><Text>Aquí puedes ver y administrar tus suscripciones adheridas a débito automático.</Text><Loader /></>);
  }

  const applyFilters = ({ values, actions }) => {
    setStatusesFilter(values.status_in);
  };

  return (
    <>
      <Text>Aquí puedes ver y administrar tus suscripciones adheridas a débito automático.</Text>
      <Grid.Container>
        <div style={{ height: "30px" }} />

        <Grid.Row>
          <Grid.Col>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                applyFilters({ values, actions });
              }}
              validateOnChange={true}
              validateOnBlur={false}
              validationSchema={schema}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={handleSubmit}
                    id="subscriptions-admin-list-filters"
                  >
                    <Filters>
                      <ReactSelect
                        name="status_in"
                        touched={touched["status_in"]}
                        error={errors["status_in"]}
                        placeholder="Estado"
                        isClearable
                        isMulti
                        items={[
                          { value: "active", label: "Activa" },
                          { value: "cancelled", label: "Cancelada" },
                          { value: "non_renewing", label: "Cancelación programada" },
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          marginBottom: 0,
                        }}
                      />

                      <Submit
                        isSubmmiting={subscriptionsStates.loading}
                        form="subscriptions-admin-list-filters"
                        style={{ alignItems: "center" }}
                        options={{
                          type: "link",
                          skin: "purple",
                          size: "lg",
                        }}
                      >
                        Filtrar
                      </Submit>
                    </Filters>
                  </form>
                );
              }}
            </Formik>
          </Grid.Col>
        </Grid.Row>

        {/* <Grid.Row>
        <Grid.Col> */}
        <Grid.Row>
          <Grid.Col>
            {subscriptions?.filter(s => s.payment_processor !== "none" && s.type === "recurring" && statusesFilter.includes(s.status))?.length > 0 ? (
              <Groups userInformation={userInformation} groups={subscriptions.filter(s => s.payment_processor !== "none" && s.type === "recurring" && statusesFilter.includes(s.status) )} />
            ) : (
              <Grid.Row>
                <Grid.Col>
                  <h3>No tienes suscripciones adheridas a un débito automático {statusesFilter?.length > 0 && statusesFilter?.length !== 3 ? "en los estados seleccionados" : ""}</h3>
                </Grid.Col>
              </Grid.Row>
            )}
            {/* </Grid.Col>
          </Grid.Row> */}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

const state = ({ userStore }) => {
  const { data: subscriptions, states: subscriptionsStates } = userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } = userStore.information;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
  };
};

export default connect(state)(Component);
