import services from "./services";

interface CheckoutParams {
  platform: "mercadopago";
  planID: number;
  couponId?: number;
}

export const mercadoPagoCheckoutUrl = async ({
  platform,
  planID,
  couponId,
}: CheckoutParams) => {
  const url = await services.subscription.getPaymentUrl({
    platform,
    plan: { id: planID },
    discounts: couponId ? [{ id: couponId }] : [],
  });

  return url.init_point;
};
