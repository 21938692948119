import styled from "styled-components";

export const CopyToClipboad = styled("button")`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  position: relative;
`;

export const Copied = styled("div")<{ copied }>`
  position: relative;
  ${({ copied }) => {
    return copied ? "display: block;" : "display: none;";
  }}

  &:before {
    ${({ copied }) => {
      return copied ? "display: block;" : "display: none;";
    }}
    content: "Copiado";
    position: absolute;
    left: 95%;
    transform: translate(-50%, 0);
    bottom: calc(100% + 15px);
    padding: 0px 10px;
    line-height: 24px;
    font-size: 14px;
    background: gray;
    color: white;
    border-radius: 4px;
  }

  &:after {
    ${({ copied }) => {
      return copied ? "display: block;" : "display: none;";
    }}
    content: "";
    position: absolute;
    left: 95%;
    transform: translate(-50%, 0);
    bottom: calc(100% + 15px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid gray;
  }
`;

export const NotCopied = styled("div")<{ copied }>`
  display: flex;
  ${({ copied }) => {
    return copied && "display: none;";
  }}

  & > img {
    display: block;
  }
`;
