import { Pill } from "./styles";
const states = {
  active: {
    label: "Activo",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  non_renewing: {
    label: "Proximo a cancelar",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  defaulted: {
    label: "Cancelado",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  cancelled: {
    label: "Cancelado",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  paid: {
    label: "Activo",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  refunded: {
    label: "Reembolsado",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  paused: {
    label: "Pausada",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  pending_invite: {
    label: "Invitado pendiente",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  expired: {
    label: "Invitado Expirado",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  in_trial: {
    label: "Periodo de prueba",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
};

const Component = ({ subscriptionState }) => {
  return (
    <Pill styles={states[subscriptionState]?.color}>
      {states[subscriptionState]?.label}
    </Pill>
  );
};

export default Component;
