import * as Yup from "yup";

export const schema = Yup.object().shape({
  external_name: Yup.string().required("Elige un nombre para tu plan."),
  currency: Yup.string().required("Selecciona el tipo de moneda."),
  type: Yup.string().required("Debes indicar un periodo de cobro."),
  period_unit: Yup.string()
    .nullable()
    .when("type", {
      is: "subscription",
      then: Yup.string().required("Indica un periodo de cobro."),
    }),
  period: Yup.number()
    .nullable()
    .when("type", {
      is: "subscription",
      then: Yup.number()
        .min(1, "Indica un periodo de cobro.")
        .required("Indica un periodo de cobro."),
    }),
  billing_cycle_type: Yup.string()
    .nullable()
    .when("type", {
      is: "subscription",
      then: Yup.string().required("Indica un ciclo de cobro."),
    }),

  // values["type"] === "charge" || values["billing_cycle_type"] === "cobro-indefinido"
  billing_cycles: Yup.number()
    .nullable()
    .when(["billing_cycle_type", "type"], {
      is: (billingCycleType, type) => type !== "charge" && billingCycleType === "cobro-tiempo-limitado",
      then: Yup.number()
        .min(1, "Indica un ciclo de cobro.")
        .required("Indica un ciclo de cobro.")
    }),
  price: Yup.number().nullable().min(1, "Indica un precio.").required("Indica un precio."),
});

interface FormValues {
  external_name: string,
  currency: string,
  type: string,
  period_unit: string,
  period: number,
  billing_cycle_type: string,
  billing_cycles: number,
  price: string,
  apply_new_price: string[],
  status: string
}

export const initialValues: FormValues = {
  external_name: "",
  currency: "ARS",
  type: "charge",
  period_unit: "month",
  period: 1,
  billing_cycle_type: "cobro-tiempo-limitado",
  billing_cycles: 1,
  apply_new_price: [],
  status: 'active',
  price: ""
};
