import whatsappIcon from "./../assets/icons/whatsapp.svg";

const whatsapp = {
  enabled: true,
  number: 541124043745,
  message: "Hola! Tengo una consulta",
  group_message: `Hola! tengo una consulta sobre el Klouser de "{name}"`,
  logged_in_group_message: `Hola! Mi cuenta es {email} y tengo una consulta sobre el Klouser de "{name}"`,
  expert_message: `Hola! soy {name} y quería hacer una consulta sobre mi Klouser!`,
  import_members: `Hola! soy {name} y quería pedirles ayuda para importar miembros a mi comunidad "{community}"`,
  checkout_failure: `Hola! soy {name} y quería pedirles ayuda para completar mi suscripcion.`,
  admin_subscriptions: `Hola! soy {name}, mi correo electrónico es {email} y quería pedirles ayuda para darme de baja en una de mis suscripciones.`,
  reactivate_subscription: `Hola! soy {name}, mi correo electrónico es {email} y quiero reactivar mi suscripcion a {group} (Nro. {subscription_id})`,
  url: "https://api.whatsapp.com/send",
  icon: whatsappIcon,
};

export default whatsapp;
