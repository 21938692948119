import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import Statistics from "../../components/Statistics";
import Interact from "./components/Interact";
import LastResourses from "../../components/LastResourses";
import LastEvents from "./components/LastEvents";
import SelectYourCountryModal from "../../components/SelectCountry";
import Button from "../../../../components/Button";
import { Page, PageHeader, Title } from "../../components/styles";
import Tabs from "../components/Tabs";
import Alert from "../../../../components/Alert";
import Grid from "../../../../components/Grid";
import Loader from "../../../../components/LogoLoader";
import { getGroupById } from "../../../../store/actions/group";
import constants from "../../../../constants";
import { getCommunityMetrics } from "../../../../store/actions/community";
import { showModal } from "../../../../store/actions/modal";
import { Group } from "../../../../types";
import { Metric } from "../../../../types/community-metric";
import { useLayout } from "../../../../providers/LayoutProvider";

interface ComponentProps {
  community: Group;
  communityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  metrics: Metric[];
}

const Component = ({ community, communityStates, metrics }: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunityMetrics());
  }, []);

  useEffect(() => {
    setLayout({
      ...layout,
      page: { title: "Tu Audiencia111" },
      dashboard: {
        tabs: {
          visible: !!community,
          activeTab: "home",
          component: [<Tabs active="home" />],
        },
      },
      header: {
        visible: !!community,
        actions: [
          // <Button
          //   onClick={() => dispatch(showModal("modal-importar-miembros"))}
          //   options={{
          //     size: "lg",
          //     type: "filled",
          //     skin: "purple",
          //   }}
          //   style={{ minWidth: "210px" }}
          // >
          //   Importar miembros
          // </Button>,
        ],
      },
    });
  }, [community, communityStates]);

  if (!community || communityStates?.loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Alert
              visible={true}
              title="¿Qué puedo hacer aquí?"
              text="Crea y comparte contenidos sin costo para tu audiencia. También es muy útil para atraer nuevas personas hacia tu comunidad. 😉"
              id="que-puedo-hacer-aqui"
            />
            <div style={{ height: "32px" }}></div>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <Statistics
        metricsObj={constants.pageCommunityHomeGroup.statistics}
        metrics={metrics}
        showPreviousPeriod={false}
      />

      <Interact />

      <div style={{height: "40px"}}/>

      {/* <LastEvents /> */}

      <Grid.Container>
        <LastResourses groupInformation={community} />
      </Grid.Container>
    </>
  );
};

const state = ({ communityStore, userStore }) => {
  const { data: community, states: communityStates } = communityStore.community;
  const { data: metrics } = communityStore.metrics;

  return { community, communityStates, metrics };
};

export default connect(state)(Component);
