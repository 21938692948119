import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle<{ background }>`
  body {
    min-height: 100vh;
    ${({ background }) => {
    if (!!background?.image) {
      return `background-image: url(${background.image}); background-repeat: no-repeat; background-size: cover;`;
    }

    if (!!background?.color) {
      return `background-color: ${background?.color};`;
    }
  }}

  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
    padding-top: 0px;
    min-height: 100vh;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 105;
  }

  #sidebars {
    position: relative;
    z-index: 150;
  }

  #modal {
    position: relative;
    z-index: 160;
  }

  #snackbar {
    position: relative;
    z-index: 170;
  }

  #swipe {
    position: relative;
    z-index: 180;
  }
`;
