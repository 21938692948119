import { Subscription } from "../types";

export const isSubscribed = (subscriptions: Subscription[], groupId) => {
  const userSubscriptions = !!subscriptions ? subscriptions : []

  return userSubscriptions
    ?.filter(
      (suscription) => suscription.status === "active" ||
        suscription.status === "paid" ||
        suscription.status === "non_renewing"
    )
    .filter((suscription) => suscription.group.id === groupId).length > 0
};
