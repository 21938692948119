import { connect, useDispatch,} from "react-redux";
import Helmet from "react-helmet";
import { Formik } from "formik";
import Button from "../../../components/Button";
import Input from "../../../components/FormikForm/Input";
import Submit from "../../../components/FormikForm/Submit";
import Link from "../../../components/Link";
import Error from "../../../components/Form/Error";
import { Center } from "../../../components/StyledComponents";

import { schema, initialValues } from "../../../constants/forms/sign-in";
import ModalPasswordRecovery from "../ModalPasswordRecovery";

import constants from "../../../constants";
import logo from "../../../assets/logo.svg";

import { GoToSignUp, Content, Title, P } from "./styles";
import { showModal } from "../../../store/actions/modal";
import { useEffect, useState } from "react";


interface ComponentProps {
  signInStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  onFormSubmit: ({ values, actions }) => void;
  setFormSubmmited: React.Dispatch<React.SetStateAction<boolean>>;
  formSubmmited: boolean;
  userStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  userType: "expert" | "subscriber";
}

const Component = ({
  signInStates,
  onFormSubmit,
  formSubmmited,
  setFormSubmmited,
  userStates,
  userType,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');

    if (emailParam)
      setEmail(emailParam);

  }, []);


  return (
    <>
      <Helmet>
        ‍<title>Klouser - Ingresa a tu cuenta</title>‍
        <meta name="description" content="Ingresa a tu cuenta de Klouser" />
      </Helmet>

      <Center
        horizontal="center"
        vertical="center"
        column
        style={{ minHeight: "100vh" }}
      >
        <Content>
          <img src={logo} width="159px" height="31px" alt="Klouser" />

          <div style={{ height: 32 }} />

          <Title>Inicia sesión </Title>

          <div style={{ height: 4 }} />

          {userType !== "subscriber" ? (
            <P>
              Somos tu plataforma para escalar y
              crecer tu negocio digital
            </P>
          ) : (
            <P>
              Acceder a todo el contenido de tus suscripciones
            </P>
          )}

          <div style={{ height: 32 }} />

          <Formik
            initialValues={{
              ...initialValues,
              email: email || initialValues.email
            }}
            onSubmit={(values, actions) => {
              if (onFormSubmit) onFormSubmit({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="sign-in-form"
                  style={{ width: "100%" }}
                >
                  <Input
                    name="email"
                    touched={touched["email"]}
                    error={errors["email"]}
                    value={values["email"]}
                    type="text"
                    placeholder="E-mail"
                    disabled={signInStates.loading}
                    onChange={(event) => {
                      event.target.value = event.target.value
                        .trim()
                        .replaceAll(" ", "");
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 16,
                    }}
                  />

                  <Input
                    name="password"
                    touched={touched["password"]}
                    error={errors["password"]}
                    value={values["password"]}
                    type="password"
                    placeholder="Contraseña"
                    disabled={signInStates.loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 6,
                    }}
                  />

                  <Center horizontal="right" vertical="center">
                    <Button
                      onClick={() =>
                        dispatch(showModal("password-recovery-modal"))
                      }
                      options={{
                        type: "link",
                        size: "sm",
                        skin: "lightGray",
                      }}
                      style={{ fontSize: "14px", lineHeight: "24px" }}
                    >
                      Olvide mi contraseña
                    </Button>
                  </Center>

                  <div style={{ height: 16 }} />

                  <Submit
                    isSubmmiting={signInStates.loading}
                    form="sign-in-form"
                    options={{
                      skin: "purple",
                      size: "lg",
                      type: "filled",
                      block: true,
                    }}
                  >
                    Ingresar!
                  </Submit>
                </form>
              );
            }}
          </Formik>

          <div style={{ height: 12 }} />

          <GoToSignUp>
            ¿No tienes cuenta?{" "}
            <Link
              href={constants.urls[userType].signup.url}
              options={{
                type: "link",
                size: "sm",
                skin: "purple",
              }}
            >
              Regístrate
            </Link>
          </GoToSignUp>

          <div style={{ height: 15 }} />

          {signInStates.error === "auth/wrong-password" && (
            <Error>
              La contraseña es incorrecta! Recibe un email para reestablecer la contraseña
              tocando aquí:{" "}
              <Button
                onClick={() => dispatch(showModal("password-recovery-modal"))}
                options={{
                  type: "link",
                  size: "sm",
                  skin: "purple",
                }}
                style={{ fontWeight: 700, fontSize: "12px" }}
              >
                Recuperar contaseña
              </Button>
            </Error>
          )}

          {signInStates.error === "auth/user-not-found" && (
            <Error>
              No existe una cuenta con el e-mail que ingresaste. Crea tu cuenta
              tocando aquí:{" "}
              <Link
                href={`constants.urls[userType].signup.url`}
                options={{
                  skin: "purple",
                  size: "xs",
                  type: "link",
                }}
              >
                Registrarme
              </Link>
            </Error>
          )}

          {signInStates.error === "auth/too-many-requests" && (
            <Error>Has hechos demasiado intentos de iniciar sesión. Espera unos minutos antes de volver a intentarlo</Error>
          )}

          {userStates.error && (
            <Error>
              Se produjo un error inesperado, por favor intenta denuevo más
              tarde.
            </Error>
          )}
        </Content>
      </Center>

      <ModalPasswordRecovery />
    </>
  );
};

const state = ({ authStore, userStore }) => {
  const { states: signInStates } = authStore.signin;
  const { states: userStates } = userStore.information;
  return {
    signInStates,
    userStates,
  };
};

export default connect(state)(Component);
