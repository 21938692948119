import {
  GET_EXPERT_GROUPS,
  GetExpertGroups,
} from "../types/expert/get-expert-groups";

import {
  GET_EXPERT_INFORMATION,
  GetExpertInformation,
} from "../types/expert/get-expert-information";

import {
  VALIDATE_EXPERT_USERNAME,
  ValidateExpertUsername,
} from "../types/expert/validate-expert-username";

export const getExpertInformation = (
  username: string
): GetExpertInformation => {
  return { type: GET_EXPERT_INFORMATION, payload: username };
};

export const getExpertGroups = (username: string): GetExpertGroups => {
  return { type: GET_EXPERT_GROUPS, payload: username };
};

export const validateExpertUsername = (
  username: string
): ValidateExpertUsername => {
  return { type: VALIDATE_EXPERT_USERNAME, payload: username };
};
