import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";
import PendingInvite from '../Profile/Subscriptions/components/PendingInvitationModal'
import GroupPreviewLayout from "../../components/Layout/GroupPreviewLayout";
import PostPreviewLayout from "../../components/Layout/PostPreviewLayout";
import PostPreview from "../Dashboard/Posts/Preview";
import WelcomeContentPreview from "../Dashboard/WelcomeContentPost/Preview";
import CategoryLibrary from "./CategoryLibrary";
import SessionPreview from "../Dashboard/Sessions/Preview";
import Preview from "./Preview";
import ActivitySummary from "./ActivitySummary";
import Library from "./Library";
import Survey from "./Survey";
import Modules from "./Modules";
import Sessions from "./Sessions";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import { showModal } from "../../store/actions/modal";
import Analytics from "./components/Analytics";
import ModulesPreviewProvider from "../../providers/ModulesPreviewProvider";
import WelcomeSectionModalMinimize from "./components/WelcomeSectionModalMinimize";

const Component = ({ subscriptions }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const location = useLocation()

  useEffect(() => {
    if (!!subscriptions) {
      const regex = /\/group\/([^\/]+)/;
      const result = location.pathname.match(regex);
      const slug = result && result[1]
      const pendingInvite = subscriptions?.find((subscrition) => (subscrition?.group?.slug === slug || subscrition?.group?.id === slug) && subscrition.status === 'pending_invite')

      if (!!pendingInvite) {
        dispatch(showModal("pending-invitation"))
      }
    }
  }, [subscriptions, location]);

  return (
    <>
      <Analytics />
      <Switch>
        <Route
          path={`${path}/:group/p/c/:category`}
          render={(props) =>
            <PostPreviewLayout useLocation={useLocation} showMenu>
              <CategoryLibrary {...props} />
            </PostPreviewLayout>}
        />

        <Route
          path={`${path}/:group/p/:id`}
          render={(props) =>
            <PostPreviewLayout useLocation={useLocation} showMenu>
              <PostPreview {...props} />
            </PostPreviewLayout>}
        />

        <Route
          path={`${path}/:group/wc/:id`}
          render={(props) =>
            <PostPreviewLayout useLocation={useLocation} showMenu>
              <WelcomeContentPreview {...props} />
            </PostPreviewLayout>}
        />

        <Route
          path={`${path}/:group/s/:id`}
          render={(props) =>
            <PostPreviewLayout useLocation={useLocation} showMenu>
              <SessionPreview {...props} />
            </PostPreviewLayout>}
        />

        <Route
          path={`${path}/:group/survey`}
          render={(props) => (
            <GroupPreviewLayout useLocation={useLocation}>
              <Survey {...props} />
            </GroupPreviewLayout>
          )}
        />

        <Route
          path={`${path}/:group/library`}
          render={(props) => (
            <GroupPreviewLayout useLocation={useLocation}>
              <Library {...props} />
            </GroupPreviewLayout>
          )}
        />

        <Route
          path={`${path}/:group/activity-summary`}
          render={(props) => (
            <GroupPreviewLayout useLocation={useLocation}>
              <ActivitySummary {...props} />
            </GroupPreviewLayout>
          )}
        />

        <Route
          path={`${path}/:group/sessions`}
          render={(props) => (
            <GroupPreviewLayout useLocation={useLocation}>
              <Sessions {...props} />
            </GroupPreviewLayout>
          )}
        />

        <Route
          path={`${path}/:group/modules`}
          render={(props) => (
            <GroupPreviewLayout useLocation={useLocation}>
              <ModulesPreviewProvider useLocation={useLocation}>
                <Modules {...props} />
              </ModulesPreviewProvider>
            </GroupPreviewLayout>
          )}
        />

        <Route
          path={`${path}/:slug`}
          render={(props) => (
            <GroupPreviewLayout useLocation={useLocation}>
              <Preview {...props} />
            </GroupPreviewLayout>
          )}
        />

        <Route path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
      </Switch>

      <PendingInvite />
      <WelcomeSectionModalMinimize />
    </>
  );
};

const state = ({ userStore }) => {
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
  };
};

export default connect(state)(Component);
