import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "../../../../components/Grid";
import { useLayout } from "../../../../providers/LayoutProvider";
import Tabs from "../components/Tabs";
import ModulesList from "./components/ModulesList";
import ModuleForm from "./components/ModuleForm";
import Link from "../../../../components/Link";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/DropdownBis";
import { getGroupById, getMetrics } from "../../../../store/actions/group";
import { useModules } from "../../../../providers/ModulesProvider";
import { Center } from "../../../../components/StyledComponents";
import ModalConfirmDelete from "./components/ModalConfirmDelete";
import { showModal } from "../../../../store/actions/modal";
import { publishModules, resetRestoreModules, restoreModules } from "../../../../store/actions/module";
import ModalShareLink from "../../components/ModalShareLink";
import ModalGoToPreview from "../components/ModalGoToPreview";
import ModalUndoModulesChanges from "../components/ModalUndoModulesChanges";
import Snackbar from "../../../../components/Snackbar";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const ctaLabel = {
  telegram: "Ir al grupo de Telegram",
  whatsapp: "Ir al grupo de Whatsapp",
};

const Component = ({ group, match }) => {
  const { isMobile } = useWindowSize()
  const [allModulesAreValid, setAllModulesAreValid] = useState<boolean>(false);
  const { modules, selectedModule, actions, setSelectedModule } = useModules();
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
      dispatch(getMetrics({ id: match.params.idGroup, filters: [] }));
    }
  }, [match]);

  useEffect(() => {
    const checkerAllModulesAreValild =
      modules?.map((module) => module.is_valid).every((v) => v === true) ||
      false;

    setAllModulesAreValid(checkerAllModulesAreValild);
  }, [modules]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: `Modulos de tu taller pregrabado ${group?.group_name}.`,
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "modules",
          component: [
            <Tabs
              active="modules"
              hiddenTabs={
                group?.recorded ? ["surveys", "library", "configuration"] : []
              }
            />,
          ],
        },
      },
      header: {
        visible: true,
        actions: [
          ...(!group?.recorded
            ? [
              <Link
                href={
                  !!group?.telegram_groups &&
                  !!group?.telegram_groups[0] &&
                  group?.telegram_groups[0]?.main_invitation_link
                }
                disabled={
                  !group?.telegram_groups || !group?.telegram_groups[0]
                }
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  native: false,
                }}
                target="_blank"
                key={"ir-al-grupo-de-chat-link"}
              >
                {group?.telegram_groups[0]?.platform
                  ? ctaLabel[group?.telegram_groups[0]?.platform]
                  : "Ir al grupo de chat"}
              </Link>,
            ]
            : []),
          <Dropdown label="" position={isMobile ? 'top' : 'bottom'}>
            <button
              onClick={() => {
                dispatch(showModal("modal-go-to-preview"));
              }}
              key={"go-to-preview-btn"}
            >
              Vista previa
            </button>
            <button
              disabled={group?.status === 'draft'}
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              key={"share-experience-btn"}
            >
              Compartir taller
            </button>
            <button
              onClick={() => {
                dispatch(showModal("modal-undo-modules-changes"));
              }}
              key={"restaurar-cambios-btn"}
            >
              Restaurar cambios
            </button>
          </Dropdown>,
          ...(group?.recorded
            ? [
              <Button
                onClick={() => {
                  actions.publish.exec({ group: group?.id });
                }}
                disabled={
                  !allModulesAreValid ||
                  !modules ||
                  (!!modules && modules.length <= 0)
                }
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                loading={actions.publish.states.loading}
                key={"publicar-taller-btn"}
              >
                {group?.status === "draft"
                  ? "Publicar taller"
                  : "Publicar cambios"}
              </Button>,
            ]
            : []),
        ],
      },
    });
  }, [group, modules, allModulesAreValid, actions.publish.states]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col sm={12}>
            <Center horizontal="space" vertical="top" gap={16} column={isMobile} style={isMobile ? { flexDirection: 'column-reverse' } : {}}>
              <ModuleForm />
              <ModulesList />
            </Center>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <ModalConfirmDelete />
      <ModalShareLink />
      <ModalUndoModulesChanges />
      <ModalGoToPreview
        title="Ver los modulos de mi taller"
        text="Hemos notado que has realizado cambios sin publicar tu última versión. ¿Cómo deseas ver la vista previa del taller?"
        buttons={[
          {
            label: "Versión sin publicar",
            link: `/group/${group?.slug}/modules?v=draft`,
            target: '_blank'
          },
          {
            label: "Versión publicada",
            link: `/group/${group?.slug}/modules?v=live`,
            target: '_blank'
          },
        ]}
      />
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;

  return {
    group,
  };
};

export default connect(state)(Component);
