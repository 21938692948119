import styled from "styled-components";

export const HorizontalScroll = styled("div")`
  overflow-x: scroll;
  max-width: 100%;
  padding-bottom: 50px;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuButton = styled('button')`
  font-weight: normal!important; 
  line-height: 26px!important; 
  color: #2d3436!important;
  width: 100%;
  text-align: right;
  display: flex!important;
  align-items: flex-end!important;
  justify-content: flex-end!important;
  padding: 0px 4px!important;

  &:hover {
    background-color: #6C5CE7;
    color: white!important;
    border-radius: 4px;
  }

  &:disabled {
    background-color: #fff!important; /* Change the background color for disabled state */
    color: #a9a9a9!important; /* Change the text color for disabled state */
    cursor: not-allowed; /* Change the cursor to indicate that the button is not clickable */
  }

  
`

export const Empty = styled("div")`
  width: calc(100% / 2);
  height: 22px;
  margin: 4px 0px;
  border-radius: 4px;
  background-color: #f5f5f5;
`;

export const Table = styled("div")`
  min-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TR = styled("div")`
  display: grid;
  grid-template-columns: 5fr 5fr 3fr 1fr 2fr 2fr 1fr;
  border-top: 1px solid #e0e4ea;
  padding: 10px 0px;

  &:first-child {
    border: 0px;
  }

  &:last-child {
    border-bottom: 1px solid #e0e4ea;
  }

  span {
    flex: 1;
  }
`;

export const TH = styled("div")`
  flex: 1;
  font-weight: 500;
  min-width: 100px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.xsMin}) {
    min-width: 300px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 200px;
  }
`;

export const TD = styled("div")`
  flex: 1;
  min-width: 100px;
  
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.xsMin}) {
    min-width: 300px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 200px;
  }

`;

export const TableHead = styled("div")``;
export const TableBody = styled("div")``;

export const EmptyState = styled("div")`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalMembers = styled("div")`
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
  //background: #6b5ce7;
  border: 1px solid #6b5ce7;
  color: #6b5ce7;
  font-size: 14px;
  border-radius: 21px;
  display: inline-block;
  line-height: 22px;
  margin-bottom: 12px;
`;
