import {
    Switch,
    Route,
    useLocation,
    useRouteMatch,
    Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import SimpleLayout from "../../components/Layout/SimpleLayout";

import CreatorsTermsOfServices from "./CreatorsTermsOfServices";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getUserGroups } from "../../store/actions/user";
import forward from "../../helpers/fowardUrl";
import { Expert } from "../../types";
import RainbowLayout from "../../components/Layout/RainbowLayout";

const Component = ({ userInformation }) => {
    const [expert, setExpert] = useState<Expert>();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserGroups({}));
        forward.goFoward();
    }, []);

    useEffect(() => {
        if (!!userInformation?.expert) {
            setExpert(userInformation?.expert);
        }
    }, [userInformation]);

    return (
        <Switch>
            <Route
                exact
                path={`${path}/`}
                render={() => <Redirect to={`${path}/creators/terms-of-services`} />}
            />


            <Route
                exact
                path={`${path}/creators/terms-of-services`}
                component={() => (
                    <RainbowLayout useLocation={useLocation}>
                        <CreatorsTermsOfServices />
                    </RainbowLayout>
                )}
            />

            <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
        </Switch>
    );
};

const state = ({ userStore }) => {
    const { data: userInformation } = userStore.information;

    return {
        userInformation,
    };
};

export default connect(state)(Component);
