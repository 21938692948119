import * as React from "react";
import styled from "styled-components";
import logoBig from "../../../../../assets/logo.svg";

const Component = () => {
  return (
  <Background>
    <span style={{fontSize: "11px", fontWeight: "500"}}>Creado vía</span>
    <ImageCard src={logoBig} alt="Creator Logo" loading="lazy"/>
  </Background>
  )
};

const Background = styled('div')`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 4px
`

const ImageCard = styled('img')`
  height: 11px;
  margin-top: 5px;
`

export default Component
