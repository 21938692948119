import Header from "../../Header";
import { Page, PageContent, GlobalStyles } from "./styles";
import { createRef, useEffect, useState } from "react";
import WhatsApp from "../../Whatsapp";
import { connect, useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actions/page";
import constants from "../../../constants";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ModalSelectChat from "../components/ModalSelectChat";

import MobileHeader from './components/MobileHeader'
import GroupMobileMenu from '../components/GroupMobileMenu'

interface ComponentProps {
  children,
  useLocation,
  showMenu,
  post,
  postStates,
  category
}

const Component = ({ children, useLocation, showMenu = true, post, postStates, category }: ComponentProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const footerRef = createRef<HTMLElement>();
  const headerRef = createRef<HTMLElement>();
  const { isMobile } = useWindowSize()
  const [minHeight, setMinHeight] = useState(56);
  const [currentPageLocally, setCurrentPageLocally] = useState<any>();

  useEffect(() => {
    const page =
      location &&
      constants.pages.filter((page) => {
        return location.pathname.match(page.regex);
      });
    setCurrentPageLocally(page[0]);
    dispatch(setCurrentPage(page[0]));
  }, [location]);

  useEffect(() => {
    if (!!headerRef.current && !!footerRef.current) {
      setMinHeight(
        headerRef.current.scrollHeight + footerRef.current.scrollHeight
      );
    }
  }, [headerRef, footerRef]);

  return (
    <>
      <GlobalStyles />

      <MobileHeader />

      <Page
        showMenu={showMenu}
        minHeight={minHeight}
        currentPage={currentPageLocally}
      >
        <PageContent>{children}</PageContent>
      </Page>

      {isMobile && (
        <GroupMobileMenu useLocation={useLocation} />
      )}

      <WhatsApp floating options={{ marginBottom: post?.is_free && isMobile ? 170 : 50 }} />

      <ModalSelectChat />
    </>
  );
};

const state = ({ postStore, categoryStore }) => {
  const { data: post, states: postStates } = postStore.post;
  const { data: category, states: categoryStates } = categoryStore.singleCategory;

  return {
    post,
    postStates,
    category,
    categoryStates
  };
};

export default connect(state)(Component);
