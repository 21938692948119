import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = (maxAmount = 999999999) => {
  return Yup.object().shape({
    group_type: Yup.string().required("Debes indicar el tipo de grupo."),
    group_id: Yup.number()
      .nullable()
      .required("Debes indicar a que grupo quieres aplicarle el descuento."),
    amount: Yup.number()
      .test(
        "is-decimal",
        "El descuento ingresado no es valido.",
        (value) => !!value && value % 1 === 0
      )
      .positive("El descuento ingresado no es valido.")
      .integer("El descuento ingresado no es valido.")
      .min(1, "Debes ingresas un descuento.")
      .max(
        maxAmount - 1,
        `El descuento no debe igualar o superar al precio de inscripción`
      )
      .required("Debes ingresas un descuento."),
    valid_till: Yup.date()
      .min(today, "Debes indicar una fecha futura")
      .required("Debes indicar hasta cuando sera válido el descuento."),
    period: Yup.number()
      .nullable()
      .when("group_type", {
        is: "membership",
        then: Yup.number()
          .min(1, "Debes indicar un periodo mínimo de 1 mes.")
          .required("Debes ingresar un número entero."),
      }),
  });
};

interface FormValues {
  group_type: string;
  group_id?: number;
  amount: string;
  valid_till: string;
  period?: number;
}

export const initialValues: FormValues = {
  group_type: "experience",
  group_id: undefined,
  amount: "",
  valid_till: "",
  period: undefined,
};
