import { put, call, all, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_PLAN,
  NEW_PLAN_SUCCESS,
  NEW_PLAN_ERROR,
  NewPlan,
} from "../types/plan/new";

import {
  GET_PLAN,
  GET_PLAN_SUCCESS,
  GET_PLAN_ERROR,
  RESET_GET_PLAN,
  GetPlan,
} from "../types/plan/get-one";

import {
  GET_PLANS,
  UPDATE_GET_PLANS,
  GET_PLANS_SUCCESS,
  GET_PLANS_ERROR,
  GetPlans,
} from "../types/plan/get-all";

import {
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_ERROR,
  DeletePlan,
} from "../types/plan/delete";

import {
  EDIT_PLAN,
  EDIT_PLAN_SUCCESS,
  EDIT_PLAN_ERROR,
  EditPlan,
} from "../types/plan/edit";

const getCurrentPlans = ({ planStore }) => planStore.allPlans.data;
const getCurrentPlan = ({ planStore }) => planStore.plan.data;

function* newPlan({ payload }: NewPlan) {
  try {
    const { response, error } = yield call(services.plan.new, payload);

    if (error) {
      yield put({ type: NEW_PLAN_ERROR, payload: error });
    } else {
      let currentPlans = yield select(getCurrentPlans);

      if (!!currentPlans && currentPlans.length > 0) {
        currentPlans = [...currentPlans, response]
      } else {
        currentPlans = [response];
      }

      yield all([
        put({ type: NEW_PLAN_SUCCESS, payload: response }),
        put({ type: GET_PLANS_SUCCESS, payload: currentPlans })
      ])
    }
  } catch (error) {
    yield put({
      type: NEW_PLAN_ERROR,
      payload: "Error inesperado al crear un nuevo precio.",
    });
  }
}

function* editPlan({ payload }: EditPlan) {
  try {
    const { response, error } = yield call(services.plan.update, payload);

    if (!!error) {
      yield put({ type: EDIT_PLAN_ERROR, payload: error });
    } else {
      const { idPlan, ...rest } = payload

      let currentPlans = yield select(getCurrentPlans);
      const currentPlan = currentPlans.find(plan => plan.id === idPlan)

      if (payload.featured) {
        currentPlans = currentPlans.map(plan => {
          if (plan.currency === currentPlan.currency) {
            return {
              ...plan,
              featured: false,
            }
          } else {
            return plan
          }
        })
      }

      const updatedPlans = currentPlans.map(item => {
        if (item.id === idPlan) {
          return { ...item, ...rest }
        }
        return item
      })

      yield all([
        put({ type: EDIT_PLAN_SUCCESS, payload: response }),
        put({
          type: GET_PLANS_SUCCESS,
          payload: updatedPlans,
        }),
      ]);
    }
  } catch (error) {
    yield put({
      type: EDIT_PLAN_ERROR,
      payload: "Error inesperado al editar un precio.",
    });
  }
}

function* deletePlan({ payload }: DeletePlan) {
  try {
    const { response, error } = yield call(services.plan.delete, payload);

    if (error) {
      yield put({ type: DELETE_PLAN_ERROR, payload: error });
    } else {
      let plansList = yield select(getCurrentPlans);

      const newPlansList = !!plansList
        ? plansList.filter((plan) => plan.id !== payload.idPlan)
        : [];

      yield all([
        put({ type: DELETE_PLAN_SUCCESS, payload: response }),
        put({ type: GET_PLANS_SUCCESS, payload: newPlansList }),
      ]);
    }
  } catch (error) {
    yield put({ type: DELETE_PLAN_ERROR, payload: "Error inesperado al borrar precio." });
  }
}

function* getPlan({ payload }: GetPlan) {
  try {
    const { response, error } = yield call(services.plan.getOne, payload);

    if (error) {
      yield put({ type: GET_PLAN_ERROR, payload: error?.response?.status });
    } else {
      yield put({ type: GET_PLAN_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: DELETE_PLAN_ERROR, payload: "Error inesperado al obtener precio." });
  }
}

function* getPlans({ payload }: GetPlans) {
  try {
    const { response, error } = yield call(services.plan.getAll, payload);

    if (error) {
      yield put({ type: GET_PLANS_ERROR, payload: error.response.status });
    } else {
      yield put({
        type: GET_PLANS_SUCCESS,
        payload: response.length > 0 ? response : null,
      });
    }
  } catch (error) {
    yield put({ type: DELETE_PLAN_ERROR, payload: "Error inesperado al obtener listado de precios." });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewPlan>(NEW_PLAN, newPlan);
  yield takeLatest<EditPlan>(EDIT_PLAN, editPlan);
  yield takeLatest<GetPlan>(GET_PLAN, getPlan);
  yield takeLatest<GetPlans>(GET_PLANS, getPlans);
  yield takeLatest<DeletePlan>(DELETE_PLAN, deletePlan);
}
