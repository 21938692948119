import * as Yup from "yup";

export const schema = Yup.object().shape({
  group_name: Yup.string().required("Debes indicar el nombre de tu grupo."),
  creator_name: Yup.string().required("Debes indicar el nombre del creador del grupo."),
  // cover_pic: Yup.array().nullable(),
  group_video: Yup.array().nullable(),
  brief: Yup.string()
    .nullable()
    .required("Agrega una descripción a tu grupo."),
    //.max(5000, "El texto no debe superar los 5000 caracteres"),
    group_perks: Yup.array()
    .of(Yup.string().max(300, 'Cada beneficio debe tener como máximo 300 caracteres.'))
    .test('not-empty', 'Error', arr => {
      console.log(!Array.isArray(arr) || arr.length === 0 || arr[0] === '' || arr[0] === undefined || arr[0] === null || !arr[0])
      if (!Array.isArray(arr) || arr.length === 0 || arr[0] === '' || arr[0] === undefined || arr[0] === null || !arr[0]) {
        return false;
      }
      return true;
    }),
  group_type: Yup.string().required("Indica el tipo de grupo que vas a crear (membresía o taller)."),
  plan: Yup.object().shape({
    type: Yup.string().required('La propiedad "plan type" es obligatorio'),
    currency: Yup.string().required('La tipo de moneda es obligatoria.'),
    price: Yup.number().required('Indica el precio de tu taller.').min(1, 'El precio es incorrecto.'),
    showable_secondary_currency: Yup.string().nullable(),
    billing_cycles: Yup.number().nullable(),
    period_unit: Yup.string().nullable(),
    period: Yup.number().nullable(),
    show_in_page: Yup.boolean().required('La propiedad "plan show_in_page" es obligatoria'),
    status: Yup.string().required('La propiedad "plan status" es obligatorio'),
  }),
});

interface FormValues {
  group_name: string,
  creator_name: string,
  // cover_pic?: object[];
  group_video?: object[];
  brief: string,
  group_perks: string[],
  group_type: 'membership' | 'experience',
  plan: {
    type: 'subscription' | 'charge',
    currency: "ARS" | "EUR" | "USD",
    showable_secondary_currency: "MXN" | "CLP" | "ARS" | "COP" | "UYU" | "PEH" | "EUR" | "USD" | "PAB",
    show_local_price: string[],
    price?: number,
    billing_cycles: null,
    period_unit: 'month' | null,
    period: 1 | null,
    show_in_page: true,
    status: "active",
  }
}

export const initialValues: FormValues = {
  group_name: '',
  creator_name: '',
  brief: '',
  group_perks: [''],
  group_type: 'experience',
  plan: {
    type: 'subscription',
    currency: "ARS",
   //price: 0,
    showable_secondary_currency: "USD",
    show_local_price: [],
    billing_cycles: null,
    period_unit: "month",
    period: 1,
    show_in_page: true,
    status: "active",
  }
};