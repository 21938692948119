import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { getGroupById, getMetrics } from "../../../../store/actions/group";
import LastResourses from "../../components/LastResourses";
import Loader from "../../../../components/LogoLoader";
import Grid from "../../../../components/Grid";
import Statistics from "../../components/Statistics";
import CallToAction from "../../components/CallToAction";
import Tabs from "../components/Tabs";
import { SectionTitle } from '../../components/Statistics/styles'
import { FilterLabel, Filters } from "./styles";
import { Actions } from "../../components/styles";
import Discount from "./components/Discount";
import ModalTelegram from "./components/ModalTelegram";
import constants from "../../../../constants";
import newContent from "../../../../assets/group/new-content.svg";
import newSession from "../../../../assets/group/new-session.svg";
import newSurveyIcon from "../../../../assets/group/new-survey.svg";
import goToTelegram from "../../../../assets/group/go-to-telegram.svg";
import editGroup from "../../../../assets//group/edit-group.svg";
import salesPage from "../../../../assets/group/sales-page.svg";
import { showModal } from "../../../../store/actions/modal";
import InitialSteps from "../../components/InitialSteps";
import Link from "../../../../components/Link";
import Button from "../../../../components/Button";
import Swipe from "../../../../components/Swipe";
import ModalShareLink from "./components/ModalShareLink";
import { SurveyInDto } from "../../../../types";
import NewSurveySidebar from "../../Survey/NewSurveySidebar";
import {
  getSurvey,
  resetNewSurvey,
} from "../../../../store/actions/survey";
import Snackbar from "../../../../components/Snackbar";
import { useLayout } from "../../../../providers/LayoutProvider";
import ReactSelect from "../../../../components/FormikForm/ReactSelect";
import { initialValues, schema } from "../../../../constants/forms/group-metrics-filters";
import { Formik } from "formik";
import { months } from "../../../../helpers/dates-hanlder";
import { CurrencyLabel } from "../../Price/components/PlanSidebar/components/NewPlanForm/styles";
import Image from "../../../../components/Image";
import flagArgentina from "../../../../constants/countries/flags/flag-AR.png";
import flagEuropeanUnion from "../../../../constants/countries/flags/flag-UE.png";
import flagUnitedStates from "../../../../constants/countries/flags/flag-US.png";
import Submit from "../../../../components/FormikForm/Submit";



interface Filter {
  [field: string]: (string | number)[];
}

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];


interface ComponentProps {
  match;
  pageContentRef?;
  currentSlide?: number;
  userInformation;
  group;
  groupStates;
  groupMetrics;
  groupMetricsStates;
  survey: SurveyInDto;
  surveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newSurvey: SurveyInDto;
  newSurveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  match,
  group,
  groupStates,
  groupMetrics,
  groupMetricsStates,
  userInformation,
  survey,
  surveyStates,
  newSurvey,
  newSurveyStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const getPreviousMonth = (monthNumber, year) => {

    // Subtract one month from the date
    const previousMonth = new Date(year, monthNumber - 2, 1);
    return `${months[previousMonth.getMonth() + 1].short} ${previousMonth.getFullYear()}`
  }


  const currentPreviousDate = getPreviousMonth(currentMonth, currentYear)

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [previousDate, setPreviousDate] = useState(currentPreviousDate);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [editCoupon, setEditCoupon] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [showNewSurveySidebar, setShowNewSurveySidebar] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup))) &&
      !groupStates.loading
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [match]);

  useEffect(() => {
    if (group && group?.survey && !survey) {
      dispatch(getSurvey({ idSurvey: group?.survey }));
    }
  }, [group]);

  const getGroupMetrics = () => {
    dispatch(getMetrics({ id: match.params.idGroup, filters }));
  }

  useEffect(() => {
    getGroupMetrics()
  }, [filters, group]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: group?.brief,
        back: `/dashboard/${group?.group_type}/list`,
      },
      dashboard: {
        tabs: {
          visible: !!group,
          activeTab: "home",
          component: [<Tabs active="home" />],
        },
      },
      header: {
        visible: !!group,
        actions: [
          <Link
            target="_blank"
            href={
              !!group?.telegram_groups &&
              !!group?.telegram_groups[0] &&
              group?.telegram_groups[0]?.main_invitation_link
            }
            disabled={!group?.telegram_groups || !group?.telegram_groups[0]}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: false,
            }}
            style={{ marginRight: "8px" }}
          >
            Ir a{" "}
            {group?.telegram_groups[0]?.platform === "telegram"
              ? "Telegram"
              : group?.telegram_groups[0]?.platform === "whatsapp" ? "Whatsapp" : "Grupo de Chat"}
          </Link>,

          <Button
            onClick={() => {
              const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
              navigator.clipboard.writeText(link);
              dispatch(showModal("modal-share-group-link"));
            }}
            disabled={!group?.telegram_groups || !group?.telegram_groups[0]}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
          >
            Compartir membresía
          </Button>,
        ],
      },
    });
  }, [group]);

  if (groupStates.loading || (!group && !groupStates.error)) {
    return <Loader />;
  }

  if (groupStates.error === "Not Found" || groupStates.error === "Forbidden") {
    return <Redirect to="/404" />;
  }

  const applyFilters = ({ values, actions }) => {
    setFilters(values);
  };

  const handleSubmit = (values, actions) => {
    // Your form submission logic here
    applyFilters({ values, actions });
    actions.setSubmitting(false); // Don't forget to setSubmitting to false when done
    setPreviousDate(getPreviousMonth(selectedMonth, selectedYear))
  };

  const currencyLabel = (currency) => {
    return (
      <CurrencyLabel>
        <Image src={currency?.flag} width="28px" height="20px" alt="" />
        {currency.iso}
      </CurrencyLabel>
    )
  }

  const allCurrenciesLabel = () => {
    return (
      <CurrencyLabel>
        Todas las monedas
      </CurrencyLabel>
    )
  }

  const currenciesFilterOptions = currencies.map((item) => ({
    label: currencyLabel(item),
    value: item.iso,
  }))

  currenciesFilterOptions.unshift({ label: allCurrenciesLabel(), value: "all" })


  return (
    <>
      <InitialSteps />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={handleSubmit}
              id="group-metrics-filters"
            >
              <Filters>
                <FilterLabel>Filtrar:</FilterLabel>

                <ReactSelect
                  name="month"
                  touched={touched["month"]}
                  error={errors["month"]}
                  placeholder="Mes"
                  isClearable
                  items={Array.from({ length: 12 }, (_, index) => {
                    const month = index + 1;

                    const isMonthDisabled = selectedYear === currentYear && month > currentMonth

                    return { value: month, label: months[month].large, isDisabled: isMonthDisabled }
                  })}
                  //items={monthItems}
                  onChange={(selectedOption) => {
                    handleChange(selectedOption);
                    const month = parseInt(selectedOption.target.value, 10);
                    setSelectedMonth(month);
                    //handleSubmit(selectedOption);
                  }}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                  }}
                />
                <ReactSelect
                  name="year"
                  touched={touched["year"]}
                  error={errors["year"]}
                  placeholder="Año"
                  isClearable
                  items={Array.from({ length: currentYear - 2020 + 1 }, (_, index) => {
                    const year = 2020 + index;

                    const isYearDisabled = year === currentYear && selectedMonth > currentMonth

                    return { value: year, label: year.toString(), isDisabled: isYearDisabled }
                  })}
                  onChange={(selectedOption) => {
                    handleChange(selectedOption);
                    const year = parseInt(selectedOption.target.value, 10);
                    setSelectedYear(year);
                    //handleSubmit(selectedOption);
                  }}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                  }}
                />

                {<ReactSelect
                  name="currency"
                  touched={touched["currency"]}
                  error={errors["currency"]}
                  placeholder="Moneda"
                  onChange={(selectedOption) => {
                    handleChange(selectedOption);
                    // handleSubmit(selectedOption);
                  }}
                  items={currenciesFilterOptions}
                  options={{
                    marginBottom: 0
                  }}
                //value={currencies.find(item => item?.iso === group?.main_currency)}
                /*onChange={(e) => {
                  setCurrency(
                    constants.currencies.filter((localItem) => {
                      return localItem.iso === e.value;
                    })[0]
                  );
                }}*/
                />}

                <Submit
                  isSubmmiting={groupMetricsStates.loading}
                  form="group-metrics-filters"
                  options={{
                    type: "link",
                    skin: "black",
                    size: "lg",
                  }}
                >
                  Aplicar filtros
                </Submit>

              </Filters>
            </form>
          );
        }}
      </Formik>

      <Statistics
        metricsObj={constants.pageMembershipHomeGroup.statistics}
        metrics={groupMetrics}
        previousMonthName={previousDate}
        showPreviousPeriod={true}
        actions={[
          {
            href: `/group/${group?.slug}`,
          },
          {
            href: `/dashboard/${group?.group_type}/${group?.id}/price`
            //onClick: () => setEditCoupon(true),
          },
          {
            href: `/dashboard/${group?.group_type === "community" ||
              group?.group_type === "free_experience"
              ? "community"
              : group?.group_type
              }/${group?.id}/members`,
          },
        ]}
      />

      <div style={{ height: 36 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <SectionTitle>Complementos de la membresía</SectionTitle>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 16 }} />

        <Grid.Row>
          <Grid.Col sm={4}>
            <CallToAction
              title="Ir al grupo de chat"
              {...(!!group?.telegram_groups && !!group?.telegram_groups[0]
                ? { link: group?.telegram_groups[0]?.main_invitation_link }
                : { action: () => dispatch(showModal("modal-telegram")) })}
              options={{
                preffix: goToTelegram,
                type: "link",
                size: "small",
                nativeLink: false,
              }}
              style={{ marginBottom: "8px" }}
            />
          </Grid.Col>

          <Grid.Col sm={4}>
            <CallToAction
              title="Vista previa de mi membresía"
              link={`/group/${group?.slug}`}
              options={{
                preffix: salesPage,
                type: "link",
                size: "small",
                nativeLink: false,
              }}
              style={{ marginBottom: "8px" }}
            />
          </Grid.Col>

          <Grid.Col sm={4}>
            <CallToAction
              title="Editar membresía"
              link={`/dashboard/membership/edit/${group?.id}`}
              options={{
                preffix: editGroup,
                type: "link",
                size: "small",
                nativeLink: true,
              }}
              style={{ marginBottom: "8px" }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: 36 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <SectionTitle>Acciones</SectionTitle>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 16 }} />

        <Grid.Row>
          <Grid.Col>
            <Actions>
              <CallToAction
                title="Subir contenido"
                description="Crea un contenido liberado para que conozcan de qué se trata tu taller"
                link={`/dashboard/${group?.group_type}/${group?.id}/posts/new`}
                options={{
                  preffix: newContent,
                  color: "rgba(253, 236, 243, 1);",
                }}
                style={{ marginBottom: "8px" }}
              />

              <CallToAction
                title="Calendarizar Encuentro"
                description="Calendariza los próximos Encuentros para que todos se enteren y reciban notificaciones"
                link={`/dashboard/${group?.group_type}/${group?.id}/sessions/new`}
                options={{
                  preffix: newSession,
                  color: "rgba(253, 236, 243, 1);",
                }}
                style={{ marginBottom: "8px" }}
              />

              <CallToAction
                active={!!survey}
                title={!!survey ? "Encuesta activa" : "Configurar encuesta"}
                description="Es ideal para que conozcan  tus propuestas y quieran seguir en contacto"
                {...(!!survey
                  ? {
                    link: `/dashboard/${group?.group_type}/${group?.id}/surveys`,
                  }
                  : { action: () => setShowNewSurveySidebar(true) })}
                options={{
                  preffix: newSurveyIcon,
                  color: "rgba(253, 236, 243, 1);",
                }}
              />
            </Actions>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: 36 }} />

      <Grid.Container>
        <LastResourses groupInformation={group} />
      </Grid.Container>

      <NewSurveySidebar
        isVisible={showNewSurveySidebar}
        onClose={() => setShowNewSurveySidebar(false)}
      />
      <ModalTelegram />
      <ModalShareLink />
      <Discount visible={editCoupon} setVisible={setEditCoupon} />

      <Snackbar
        visible={newSurveyStates.success || !!newSurveyStates.error}
        options={{
          type: newSurveyStates.success ? "success" : "error",
          position: "center",
        }}
        onHide={() => {
          window.location.href = `/dashboard/${group?.group_type}/${group?.id}/surveys`;
          dispatch(resetNewSurvey());
        }}
      >
        {newSurveyStates.success
          ? "Encuesta creada correctamente."
          : `Error al crear encuesta.`}
      </Snackbar>

      <Swipe name="swipe-menu">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`
            );
          }}
          disabled={!group?.telegram_groups || !group?.telegram_groups[0]}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Compartir taller
        </Button>
      </Swipe>
    </>
  );
};

const state = ({ groupStore, userStore, surveyStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: survey, states: surveyStates } = surveyStore.selected;
  const { data: newSurvey, states: newSurveyStates } = surveyStore.newOne;
  const { data: groupMetrics, states: groupMetricsStates } = groupStore.metrics;

  return {
    group,
    survey,
    surveyStates,
    newSurvey,
    newSurveyStates,
    groupStates,
    userInformation,
    groupMetrics,
    groupMetricsStates,
  };
};

export default connect(state)(Component);
