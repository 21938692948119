import Modal from "../../../Modal";
import Icon from "../../../Icon";
import Link from "../../../Link";
import { Title, Text, Label } from "./styles";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import NoSubscribed from '../NoSubscribed'
import { Center } from "../../../StyledComponents";
import TelegramIcon from "../../../../assets/icons/telegram.svg";
import WhatsappIcon from "../../../../assets/icons/whatsapp.svg";
import MobileIcon from "../../../../assets/icons/mobile.svg";
import MessagesIcon from "../../../../assets/icons/messages.svg";
import { getExpertTelegramGroup, getSubscriberTelegramGroup } from "../../../../store/actions/group";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import { getFreeSubscriptions } from "../../../../helpers/getFreeSubscriptions";


const subscriberTypesLabels = {
  broadcast: "Canal de Novedades ",
  broadcast_with_comments: "Canal de Novedades",
  broadcast_with_comments_and_chat: "Canal de Novedades",
  group_chat: "Chat Grupal",
};

const ownerTypesLabels = {
  broadcast: "Canal de Difusión",
  broadcast_with_comments: "Canal de Difusión con Comentarios",
  broadcast_with_comments_and_chat: "Canal de Difusión",
  group_chat: "Grupo de Chat",
};

const platformsSettings = {
  whatsapp: {
    icon: WhatsappIcon,
    name: "Whatsapp",
    color: "#24d366",
    link: "https://www.whatsapp.com/",
    skin: "lightGreen"
  },
  telegram: {
    icon: TelegramIcon,
    name: "Telegram",
    color: "#039be5",
    link: "http://onelink.to/84gpfk",
    skin: "lightBlue"
  },
};


const Component = ({
  user,
  userStates,
  group,
  groupStates,
  subscriptions,
  subscriptionsStates,
  subscriberTelegramUrl,
  subscriberTelegramUrlStates,
  expertTelegramUrl,
  expertTelegramUrlStates,
  post,
  postStates,
  isLoggedIn,
  userSubscriptions
}) => {
  const dispatch = useDispatch();
  const [platform, setPlatform] = useState<string>('')
  const [chatType, setChatType] = useState<string>('')
  const [discussinInvitationLink, setDiscussinInvitationLink] = useState<string | null>(null)

  const isOwnGroup = ({ group, post, user }) => {
    const expertId = group?.expert?.id || post?.group?.expert?.id
    return expertId === user?.expert?.id;
  };

  const typesLabels = useMemo(() => {
    return isOwnGroup({ group, post, user }) ? ownerTypesLabels : subscriberTypesLabels
  }, [group, post, user])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  useEffect(() => {
    if (((!!group && !groupStates.loading) || (!!post && !postStates.loading)) && !userStates.loading) {
      const groupId = group?.id || post?.group?.id
      const groupType = group?.group_type || post?.group?.group_type

      if (user && isOwnGroup({ group, post, user })) {
        dispatch(getExpertTelegramGroup(groupId));
      } else if (groupType !== "free_experience") {
        dispatch(getSubscriberTelegramGroup(groupId));
      }
    }
  }, [group, groupStates, post, postStates]);

  useEffect(() => {
    const platform = group?.telegram_groups[0]?.platform || post?.group?.telegram_groups[0]?.platform;
    const type = group?.telegram_groups[0]?.type || post?.group?.telegram_groups[0]?.type;
    const link = group?.telegram_groups[0]?.discussion_invitation_link || post?.group?.telegram_groups[0]?.discussion_invitation_link;
    setPlatform(platform);
    setChatType(type);
    setDiscussinInvitationLink(link)
  }, [post, group])

  return (
    <Modal
      name="modal-select-chat"
      width="650"
      showClose={true}
      onClose={() => { }}
    >

      {(isUserSubscribed || isOwnGroup({ group, post, user })) && (
        <Center horizontal="right" vertical="center" column>
          <Icon
            icon={platformsSettings[platform]?.icon}
            color={platformsSettings[platform]?.color}
            size="83px"
          />

          <div style={{ height: 24 }} />

          <Title>Te estamos por redirigir a {platformsSettings[platform]?.name}! </Title>

          <div style={{ height: 32 }} />

          <Link
            target="_blank"
            href={
              group?.group_type === "free_experience" ? group?.telegram_groups[0]?.main_invitation_link : (
                isOwnGroup({ group, post, user }) ? expertTelegramUrl : subscriberTelegramUrl)
            }
            disabled={group?.group_type !== "free_experience" && (
              (isOwnGroup({ group, post, user })
                ? expertTelegramUrlStates.loading ||
                (!expertTelegramUrlStates.loading && !expertTelegramUrl)
                : subscriberTelegramUrlStates.loading ||
                (!subscriberTelegramUrlStates.loading && !subscriberTelegramUrl)) ||
              groupStates.loading)
            }
            loading={
              (isOwnGroup({ group, post, user })
                ? expertTelegramUrlStates.loading
                : subscriberTelegramUrlStates.loading) || groupStates.loading
            }
            options={{
              skin: platformsSettings[platform]?.skin,
              size: "lg",
              type: "filled",
              native: false,
              block: true
            }}
          >
            <Center horizontal="center" vertical="center" gap={8}>
              <Icon
                icon={MobileIcon}
                color="white"
                size="24px"
              />
              <Label>Ir al {typesLabels[group?.telegram_groups[0]?.type]}</Label>
            </Center>
          </Link>

          {chatType === "broadcast_with_comments_and_chat" && !!discussinInvitationLink && (
            <>
              <div style={{ height: 16 }} />
              <Link
                target="_blank"
                href={discussinInvitationLink}
                disabled={false}
                options={{
                  skin: platformsSettings[platform]?.skin,
                  size: "sm",
                  type: "outline",
                  native: false,
                  block: true,
                }}
              >
                <Center horizontal="center" vertical="center" gap={8}>
                  <Icon
                    icon={MessagesIcon}
                    color="black"
                    size="24px"
                  />
                  <Label> Ir al Chat Grupal</Label>
                </Center>
              </Link>
            </>
          )}

          <div style={{ height: 32 }} />

          <Text>¿No tienes {platformsSettings[platform]?.name}? No te preocupes, ingresa a este <a href={platformsSettings[platform]?.link} target="_blank">link</a> para descargar la app y vuelve a esta pantalla para ingresar al gurpo. </Text>
        </Center>
      )}

      <NoSubscribed style={{ position: 'relative' }} chat />
    </Modal>
  );
};

const state = ({ groupStore, userStore, postStore }) => {
  const { data: subscriberTelegramUrl, states: subscriberTelegramUrlStates } =
    groupStore?.subscriberTelegramGroup;
  const { data: expertTelegramUrl, states: expertTelegramUrlStates } =
    groupStore?.expertTelegramGroup;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: user, states: userStates } = userStore.information;
  const { data: post, states: postStates } = postStore.post;
  const { isLoggedIn } = userStore;
  const { data: userSubscriptions } = userStore.subscriptions;

  return {
    user,
    userStates,
    group,
    groupStates,
    post,
    postStates,
    subscriptions,
    subscriptionsStates,
    subscriberTelegramUrl,
    subscriberTelegramUrlStates,
    expertTelegramUrl,
    expertTelegramUrlStates,
    isLoggedIn,
    userSubscriptions
  };
};

export default connect(state)(Component);