import { combineReducers } from "redux";
import information from "./get-expert-information";
import groups from "./get-expert-groups";
import expertExist from "./validate-expert-username";

const reducer = combineReducers({
  information,
  groups,
  expertExist,
});

export default reducer;
