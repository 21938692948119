import styled from "styled-components";

export const Pill = styled("span") <{ styles }>`
  display: inline-block;

  background: ${({ styles }) => styles?.background};
  color: ${({ styles }) => styles?.text};
  line-height: 20px;
  border-radius: 5px;
  padding: 5px 10px;
`;
