import Helmet from "react-helmet";
import { useEffect, useRef, useState } from "react";
import Alert from "../../../../components/Alert";
import Grid from "../../../../components/Grid";
import Members from "../../components/MembersList";
import { Page, PageHeader, Title } from "../../components/styles";
import { connect, useDispatch } from "react-redux";
import { getGroupById, getGroupSubscribers, getMetrics } from "../../../../store/actions/group";
import Input from "../../../../components/FormikForm/Input";
import ReactSelect from "../../../../components/FormikForm/ReactSelect";
import Submit from "../../../../components/FormikForm/Submit";
import Button from '../../../../components/Button'
import { Filters, FilterLabel } from "./styles";
import { Formik } from "formik";
import Tabs from "./../components/Tabs";
import InviteMembers from "../../components/MembersList/components/InviteMembers";
import {
  initialValues,
  schema,
} from "../../../../constants/forms/subscribers-filters";
import { Group } from "../../../../types";
import { useLayout } from "../../../../providers/LayoutProvider";

interface Filter {
  [field: string]: (string | number)[];
}

interface ComponentProps {
  match,
  group: Group;
  groupStates: {
    loading: boolean,
    error: string | boolean,
    success: boolean
  }
  groupSubscribersInformation;
  groupSubscribersStates;
}

const Component = ({
  match,
  group,
  groupStates,
  groupSubscribersInformation,
  groupSubscribersStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { layout, setLayout } = useLayout();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filter[]>([]);

  const [isOpened, setIsOpened] = useState(false);


  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup))) &&
      !groupStates.loading
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
      dispatch(getMetrics({ id: match.params.idGroup, filters: [] }));
    }
  }, [match]);

  const getSubscribres = () => {
    dispatch(getGroupSubscribers({ page, id: group?.id, filters }));
  };

  const paginate = (page) => {
    setPage(page);
  };

  const applyFilters = ({ values, actions }) => {
    setFilters(values);
  };


  useEffect(() => {
    getSubscribres();
  }, [filters, page, group]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: `Listado de miembros de tu membresía ${group?.group_name}.`,
        back: `/dashboard/${group?.group_type}/${group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "members",
          component: [<Tabs active="members" />],
        },
      },
      header: {
        visible: true,
        actions: [  <Button
          onClick={() => {
            setIsOpened(true)
          }}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Agregar Miembros
        </Button>]
      },
    });
  }, [group, isOpened]);

  return (
    <>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Alert
            visible={true}
            title="Aumenta el numero de suscriptores!"
            text="No olvides promocionar tu grupo compartiendo el Link en tus redes sociales."
            id="aumenta-tus-suscriptores"
          />
        </Grid.Col>
      </Grid.Row>

      <div style={{ height: 30 }} />

      <Grid.Row>
        <Grid.Col>
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              applyFilters({ values, actions });
            }}
            validateOnChange={true}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={handleSubmit}
                  id="subscribers-list-filters"
                >
                  <FilterLabel>Filtros</FilterLabel>
                  <Filters>
                    <Input
                      name="email"
                      touched={touched["email"]}
                      error={errors["email"]}
                      value={values["email"]}
                      type="string"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <ReactSelect
                      name="status"
                      touched={touched["status"]}
                      error={errors["status"]}
                      placeholder="Estado"
                      isClearable
                      isMulti
                      items={[
                        { value: "active", label: "Activo" },
                        { value: "cancelled", label: "Cancelado" },
                        { value: "non_renewing", label: "Proximo a vencer" },
                        { value: "pending_invite", label: "Invitado Pendiente" },
                        { value: "expired", label: "Invitado Expirado" },
                        { value: "refunded", label: "Reembolsado" },
                      ]}
                      onChange={handleChange}
                      onOptionSelected={(field, data) => {
                        // if (
                        //   data.action === "remove-value" &&
                        //   filters[filterName]
                        // ) {
                        //   const filterValue = filters[filterName];
                        //   delete filters[filterName][data.removedValue.value];
                        //   console.log("filterValue", filterValue);
                        //   if (typeof filterValue === "object") {
                        //     handleChange({
                        //       [field.name]: filters[filterName],
                        //     });
                        //   }
                        // }
                      }}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <Submit
                      isSubmmiting={groupSubscribersStates.loading}
                      form="subscribers-list-filters"
                      options={{
                        type: "link",
                        skin: "purple",
                        size: "lg",
                      }}
                    >
                      Aplicar filtros
                    </Submit>
                  </Filters>

                  {/* <AutoSave /> */}
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>


      <Grid.Row>
        <Grid.Col>
          <Members
            states={groupSubscribersStates}
            data={groupSubscribersInformation?.data}
            filters={filters}
            pagination={groupSubscribersInformation?.pagination}
            goToPage={paginate}
            groupId={group?.id}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>

    <InviteMembers isOpened={isOpened} onClose={() => setIsOpened(false)}/>
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupSubscribersInformation, states: groupSubscribersStates } =
    groupStore.groupSubscribers;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
    groupSubscribersInformation,
    groupSubscribersStates,
  };
};

export default connect(state)(Component);
