import styled from "styled-components";

export const Button = styled("button") <{
  options;
  block?: boolean;
  disabled?: boolean;
}>`
  ${(props: any) => {
    const { options, theme, disabled } = props;
    const { size, type, skin, block } = options;

    return `
      font-family: 'Inter',sans-serif;
      cursor: ${!disabled ? "pointer" : "default"};
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      ${!(type !== "common" && type !== "link") || skin === "transparent"
        ? ``
        : `border: 1px solid ${theme.colors[theme.button.colors[type][skin].borderColor]
        }`
      };
      ${type !== "link" ? `height: ${theme.button.size[size].height}` : ``};
      background-color: ${theme.colors[theme.button.colors[type][skin].backgroundColor] || "transparent"
      };
      font-size: ${theme.button.size[size].fontSize};
      color: ${theme.colors[theme.button.colors[type][skin].color]};
      border-radius: ${theme.button.size[size].borderRadius};
      padding: ${type === "link" ? "0px" : "0px 17px"};
      text-decoration: ${type === "link" ? "underline" : "none"};
      letter-spacing: 0.2px;
      font-weight: ${type === "link" ? "normal" : "600"};
      ${block ? `width: 100%;` : ``}
      min-width: ${type === "link" ? "0" : "150px"};
      text-align: center;
      transition: all .2s ease-in-out;
      opacity: ${!disabled ? "1" : ".5"};

      ${!disabled &&
      `&:hover {
          ${type !== "link"
        ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
        : "text-decoration: underline;"
      }
        }`
      }
    `;
  }}
`;
