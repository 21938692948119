import styled from "styled-components";

const positions = {
  bottom: {
    top: 'calc(100% + 5px)'
  },
  top: {
    bottom: 'calc(100% + 5px)'
  }
}

export const List = styled('ul') <{ position, isOpen }>`
  ${({ isOpen }) => isOpen ? `
    z-index: 1;
    opacity: 1;
    pointer-events: all;
  ` : `
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  `}
  background-color: white;
  padding: 10px 0px;
  margin: 0px;
  position: absolute;
  ${({ position }) => positions[position]}
  right: 0%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10);
  border-radius: 6px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: calc(100% + 10px);
  }

  li {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
