import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import {
  Preview,
  Text,
  Title,
  Option,
  StrongLabel,
  Value,
  StrongValue,
} from "./styles";
import { DiscountDto } from "../../../../../../types/discount";
import {
  initialValues,
  schema,
} from "../../../../../../constants/forms/discount";
import { useEffect, useState } from "react";
import {
  dateSpliter,
  inputDateTimeLocal,
  setToZeroTimeZone,
} from "../../../../../../helpers/dates-hanlder";
import { connect, useDispatch } from "react-redux";
import { Group, PlanDto } from "../../../../../../types";
import constants from "../../../../../../constants";
import moment from "moment";
import { hideModal } from "../../../../../../store/actions/modal";
import { Formik } from "formik";
import Input from "../../../../../../components/FormikForm/Input";
import Submit from "../../../../../../components/FormikForm/Submit";
import {
  newDiscount,
  resetNewDiscount,
  resetUpdateDiscount,
  selectedDiscount,
  updateDiscount,
} from "../../../../../../store/actions/discount";

const getItem = (iso) => {
  return constants.currencies.filter((currency) => currency?.iso === iso)[0];
};

const isFutureDate = (date) => {
  const selectedDate = moment(date);
  const today = moment();

  return selectedDate.diff(today) >= 0;
};

const groupType = {
  membership: "Membresia",
  experience: "Taller",
};

interface ComponentProps {
  // coupon: DiscountDto;
  // allDiscounts: DiscountDto[];
  // deleteDiscountStates: {
  //   loading: boolean;
  //   error: string | boolean;
  //   success: boolean;
  // };
  // allDiscountsStates: {
  //   loading: boolean;
  //   error: string | boolean;
  //   success: boolean;
  // };
  visible: boolean;
  setVisible: (visible: boolean) => void;
  discount;
  newDiscountInformation: DiscountDto;
  newDiscountStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  updateDiscountInformation: DiscountDto;
  updateDiscountStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  groupInformation: Group;
  onClose?: (data: any) => void;
  // setShowDesactivateCouponSidebar: (show: boolean) => void;
  // setShowEditCouponSidebar: (show: boolean) => void;
}

const Component = ({
  visible,
  setVisible,
  discount,
  newDiscountInformation,
  newDiscountStates,
  updateDiscountInformation,
  updateDiscountStates,
  groupInformation,
  onClose,
}: // setShowDesactivateCouponSidebar,
  // setShowEditCouponSidebar,
  ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [plan, setPlan] = useState<PlanDto>();
  //const [discount, setDiscount] = useState<DiscountDto>();
  const [currency, setCurrency] = useState<string>();

  const onSubmit = ({ values, actions }) => {
    const discountPayload = {
      ...values,
      group_id: groupInformation?.id,
      period: values.period ? Number(values.period) : null,
      amount: Number(plan?.price) - Number(values.amount),
      valid_till: setToZeroTimeZone(values.valid_till),
    };

    if (!!discount) {
      dispatch(updateDiscount(discountPayload));
    } else {
      dispatch(newDiscount(discountPayload));
    }
  };

  useEffect(() => {
    if (!!groupInformation) {
      setPlan(groupInformation.plans[0]);
    }
  }, [groupInformation]);

  useEffect(() => {
    if (plan) {
      setCurrency(getItem(plan?.currency).label);
    }

    if (plan?.discounts?.length) {
      dispatch(
        selectedDiscount(
          plan?.discounts?.filter((discount) => discount.show_in_page)[0]
        )
      );
    }
  }, [plan]);

  // useEffect(() => {
  //   if (!!newDiscountInformation) {
  //     setDiscount(newDiscountInformation);
  //   }
  // }, [newDiscountInformation]);

  // useEffect(() => {
  //   if (!!updateDiscountInformation) {
  //     setDiscount(updateDiscountInformation);
  //   }
  // }, [updateDiscountInformation]);

  useEffect(() => {
    if (newDiscountStates.success || updateDiscountStates.success) {
      setVisible(false);
      // dispatch(resetNewDiscount());
      // dispatch(resetUpdateDiscount());
    }
  }, [newDiscountStates, updateDiscountStates]);

  return (
    <>
      <Sidebar
        position="right"
        visible={visible}
        onClose={() => {
          setVisible(false);
          onClose && onClose(undefined);
        }}
        width="463px"
        title="Lanza una promoción"
      >
        <Preview>
          <div>
            <Text style={{ marginBottom: "50px" }}>
              Esta información aparecerá destacada en tu página de venta para
              atraer a más personas
            </Text>

            <Option>
              <StrongLabel>Valor inicial de inscripción</StrongLabel>
              <Value>
                {currency} {plan?.price}
              </Value>
            </Option>

            {!!plan && !!discount && discount?.redemptions > 0 && (
              <Option>
                <StrongLabel>Valor promocional</StrongLabel>
                <StrongValue>
                  {currency} {plan?.price - discount?.amount} por {plan?.period}{" "}
                  {plan.period === 1 ? "mes" : "meses"}
                </StrongValue>
              </Option>
            )}

            <Option>
              <Formik
                initialValues={{
                  ...initialValues,
                  group_id: groupInformation?.id,
                  show_in_page: true,
                  type: "fixed_amount",
                  status: "active",
                  ...(!!plan &&
                    !!discount && {
                    id: discount?.id,
                    amount: plan?.price - discount?.amount,
                    valid_till: discount?.valid_till,
                    period: discount?.period,
                  }),
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={
                  !!plan && !!discount ? schema(plan?.price) : schema()
                }
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      id="edit-coupon-form"
                    >
                      {(!discount || discount?.redemptions <= 0) && (
                        <Input
                          name="amount"
                          error={errors["amount"]}
                          touched={touched["amount"]}
                          value={values["amount"]}
                          type="number"
                          step="any"
                          placeholder="Ej: 10"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={{
                            label: "Valor promocional",
                            before: currency,
                            marginBottom: 24,
                          }}
                        />
                      )}

                      <Input
                        name="valid_till"
                        error={errors["valid_till"]}
                        touched={touched["valid_till"]}
                        type="datetime-local"
                        placeholder="dd/mm/aa"
                        min={moment().format("YYYY-MM-DDThh:mm")}
                        value={inputDateTimeLocal(values?.valid_till)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          label: "Descuento válido hasta:",
                          marginBottom: 24,
                        }}
                      />

                      {(!discount || discount?.redemptions <= 0) && (
                        <Input
                          name="period"
                          error={errors["period"]}
                          touched={touched["period"]}
                          value={values["period"]}
                          type="number"
                          placeholder="Número de meses"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={{
                            label: "Descuento válido por",
                            after: "meses",
                            explainText:
                              "Elige cuánto tiempo se aplicará el descuento. Por ejemplo, si eliges 2 meses, las personas pagarán tu membresía con descuento durante los próximos 2 meses.",
                            marginBottom: 0,
                          }}
                        />
                      )}
                    </form>
                  );
                }}
              </Formik>
            </Option>
          </div>

          <div>
            <Submit
              form="edit-coupon-form"
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              isSubmmiting={updateDiscountStates.loading}
              style={{ marginBottom: "8px" }}
            >
              {!!discount ? "Guardar cambios" : "Aplicar promoción"}
            </Submit>

            <Button
              options={{
                type: "link",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => setVisible(false)}
            >
              Cancelar
            </Button>
          </div>
        </Preview>
      </Sidebar>
    </>
  );
};

const state = ({ groupStore, discountStore }) => {
  const { updateDiscount, newDiscount, selectedDiscount } = discountStore;
  const { data: updateDiscountInformation, states: updateDiscountStates } =
    updateDiscount;
  const { data: newDiscountInformation, states: newDiscountStates } =
    newDiscount;
  const { data: groupInformation } = groupStore.group;
  const { data: discount } = selectedDiscount;

  return {
    discount,
    newDiscountInformation,
    newDiscountStates,
    updateDiscountInformation,
    updateDiscountStates,
    groupInformation,
  };
};

export default connect(state)(Component);
