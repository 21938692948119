import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Link from "../../../../components/Link";
import Button from "../../../../components/Button";
import Alert from "../../../../components/Alert";
import ModalNewGroup from "./components/ModalNewGroup";
import ModalExperienceType from "../components/ExperienceTypeModal";
import FilterByState from "./components/FilterByState";
import { ActionsBar, CardsGrid, SectionTitle, StickyTitle } from "./styles";
import Grid from "../../../../components/Grid";
import GroupCard from "../../../../components/GroupCard";
import slugify from "slugify";
import { Group } from "../../../../types";
import constants from "../../../../constants";
import { useLayout } from "../../../../providers/LayoutProvider";
import { showModal } from "../../../../store/actions/modal";
import Switch from "../../../../components/FormikForm/Switch";
import { Center } from "../../../../components/StyledComponents";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import EmptyCard from "../../components/EmptyCard";

import iconLaptop from "../../../../assets/icons/laptop.svg";
import iconUsers from "../../../../assets/icons/users-2.svg";
interface ComponentProps {
  userInformation;
  userGroups: Group[];
  match;
}

const wording = {
  membership: {
    title: "Mis Membresías",
    cta: "Crear una membresía",
  },
  experience: {
    title: "Mis talleres",
    cta: "Crear una experiencia",
  },
};

const Component = ({ match, userGroups, userInformation }: ComponentProps) => {
  const [showInactiveRecordedGroups, setShowInactiveRecordedGroups] = useState(false)
  const [showInactiveLiveGroups, setShowInactiveLiveGroups] = useState(true)
  const { layout, setLayout } = useLayout();
  const { isMobile } = useWindowSize()
  const groupType = "experience";
  const [groups, setGroups] = useState<Group[]>();
  const [filteredGroups, setFilteredGroups] = useState<Group[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!userGroups && !!groupType) {
      setGroups(userGroups.filter((group) => group?.group_type === groupType));
    }
  }, [userGroups, groupType]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: wording[groupType]?.title,
        description: wording[groupType]?.title,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Button
            onClick={() => dispatch(showModal("modal-select-experience-type"))}
            //href={constants.urls.dashboard.newExperience.url}
            options={{
              size: "lg",
              type: "filled",
              skin: "purple",
            }}
            style={{ minWidth: "210px" }}
          >
            Crear taller
          </Button>,
        ],
      },
    });
  }, [userGroups]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Grid.Row>
              <Grid.Col>
                Lleva un control del estado de tus talleres, comparte el link
                para invitar a más personas, edita cuando desees y ¡Anímate a
                crear uno nuevo! 😎
              </Grid.Col>
            </Grid.Row>

            <div style={{ height: "32px" }} />

            <Grid.Row>
              <Grid.Col>
                <Alert
                  visible={true}
                  title="Empecemos a motivar a más personas 😎"
                  text="Desde esta sección podrás crear talleres para tu audiencia."
                  id="empecemos-a-motivar-a-mas-personas"
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row style={{ display: 'none' }}>
              <Grid.Col>
                <ActionsBar>
                  <FilterByState
                    groups={groups}
                    reset={match.url}
                    setFilteredGroups={setFilteredGroups}
                  />
                </ActionsBar>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <StickyTitle>
        <Grid.Row>
          <Grid.Col>
            <Center horizontal="space" vertical="center">
              <SectionTitle>Talleres Pregrabados</SectionTitle>
              {/* <Center horizontal="right" vertical="center" gap={10} style={{ width: 'auto' }}>{`${!isMobile ? 'Mostrar' : ''} Inactivos`} <Switch checked={showInactiveRecordedGroups} onChange={(checked) => setShowInactiveRecordedGroups(checked)} /></Center> */}
            </Center>
          </Grid.Col>
        </Grid.Row>
      </StickyTitle>

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <CardsGrid>
              {!!filteredGroups &&
                !!userInformation &&
                filteredGroups
                  .filter((item) => item.recorded)
                  .filter((item) => showInactiveRecordedGroups || item.status === 'active' || item.status === 'draft')
                  .map((group) => {
                    const { expert, ...rest } = userInformation;
                    return (
                      <GroupCard
                        key={slugify(String(group?.id), { lower: true })}
                        group={group}
                        expert={expert}
                        editLink={`/dashboard/${groupType}/edit/${group?.id}`}
                        homeGroupLink={`/dashboard/${groupType}/${group?.id}`}
                        previewLink={`${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`}
                      />
                    );
                  })}

              {!filteredGroups || !userInformation || filteredGroups
                .filter((item) => item.recorded)
                .filter((item) => showInactiveRecordedGroups || item.status === 'active' || item.status === 'draft').length <= 0 && (
                  <EmptyCard
                    title="No tienes talleres pregrabados"
                    text="Crea tu primer taller pregrabado y genera ingresos pasivos."
                    icon={iconLaptop}
                    link={{
                      label: "Crear taller pregrabado",
                      url: "/dashboard/experience/new?r=t",
                    }}
                  />
                )}
            </CardsGrid>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: "64px" }} />

      <StickyTitle>
        <Grid.Row>
          <Grid.Col>
            <Center horizontal="space" vertical="center" >
              <SectionTitle>Talleres en vivo</SectionTitle>
              {!!filteredGroups && !!userInformation && filteredGroups
                .filter((item) => !item.recorded).length > 0 && (
                  <Center horizontal="right" vertical="center" gap={10} style={{ width: 'auto' }}>
                    <span style={{ flexShrink: 0 }}>{`${!isMobile ? 'Mostrar' : ''} Inactivos`}</span>
                    <Switch
                      value={[...(showInactiveLiveGroups ? ["true"] : [])]}
                      name="show_inactive_live_groups"
                      onChange={(event) => {
                        const isChecked = event?.target.checked;
                        setShowInactiveLiveGroups(isChecked)
                      }}
                      items={[
                        {
                          label: "",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 0,
                      }}
                    />
                  </Center>
                )}
            </Center>
          </Grid.Col>
        </Grid.Row>
      </StickyTitle>

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <CardsGrid>
              {!!filteredGroups &&
                !!userInformation &&
                filteredGroups
                  .filter((item) => !item.recorded)
                  .filter((item) => showInactiveLiveGroups || item.status === 'active')
                  .map((group) => {
                    const { expert, ...rest } = userInformation;
                    return (
                      <GroupCard
                        key={slugify(String(group?.id), { lower: true })}
                        group={group}
                        expert={expert}
                        editLink={`/dashboard/${groupType}/edit/${group?.id}`}
                        homeGroupLink={`/dashboard/${groupType}/${group?.id}`}
                        previewLink={`${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`}
                      />
                    );
                  })}

              {!filteredGroups || !userInformation || filteredGroups
                .filter((item) => !item.recorded)
                .filter((item) => showInactiveLiveGroups || item.status === 'active').length <= 0 && (
                  <EmptyCard
                    title="No tienes talleres en vivo"
                    text="Descubre cómo crear y compartir tu primer taller en vivo con tu comunidad."
                    icon={iconUsers}
                    link={{
                      label: "Crear taller en vivo",
                      url: "/dashboard/experience/new?r=f",
                    }}
                  />
                )}
            </CardsGrid>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container >

      <div style={{ height: "32px" }} />

      <ModalExperienceType />
      <ModalNewGroup />
    </>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;

  return {
    userInformation,
    userGroups,
    userGroupsStates,
  };
};

export default connect(state)(Component);
