import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import PhoneInput from "../../../components/FormikForm/PhoneInput";
import Input from "../../../components/FormikForm/Input";
import Submit from "../../../components/FormikForm/Submit";
import { Center } from "../../../components/StyledComponents";
import Snackbar from "../../../components/Snackbar";

import { schema, initialValues } from "../../../constants/forms/phone";
import {
  changeUserPhone,
  resetchangeUserPhone,
} from "../../../store/actions/user";
import { useEffect, useState } from "react";
import { User } from "../../../types";

interface ComponentProps {
  userInformation: User;
  changeUserPhoneStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  changeUserPhoneStates,
  userInformation,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);

  const onFormSubmit = ({ values, actions }) => {
    dispatch(
      changeUserPhone({
        ...values,
        userId: userInformation.id,
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetchangeUserPhone());
    }, 5000);
  }, [changeUserPhoneStates]);

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          phone_area_code: userInformation?.phone_area_code,
          phone_number: userInformation?.phone_number,
        }}
        onSubmit={(values, actions) => {
          if (onFormSubmit) onFormSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="phone-number-form"
              style={{ width: "100%" }}
            >
              <PhoneInput
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                values={values}
                disabled={changeUserPhoneStates.loading}
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  marginBottom: 16,
                }}
              />
            </form>
          );
        }}
      </Formik>

      <Snackbar
        visible={changeUserPhoneStates.success || !!changeUserPhoneStates.error}
        options={{
          time: 2000,
          type: changeUserPhoneStates.success ? "success" : "error",
        }}
      >
        {changeUserPhoneStates.success
          ? "Teléfono configurado."
          : "Error al configurado el teléfono."}
      </Snackbar>
    </>
  );
};

const state = ({ userStore }) => {
  const { states: changeUserPhoneStates } = userStore.changeUserPhone;
  const { data: userInformation } = userStore.information;
  return {
    changeUserPhoneStates,
    userInformation,
  };
};

export default connect(state)(Component);
