import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import constants from "../../constants";
import foward from "../../helpers/fowardUrl";
import { AuthContext } from "../../providers/Auth";

const Component = ({
  component: RouteComponent,
  userInformation,
  ...rest
}: any) => {
  const { user } = useContext(AuthContext);
  const hasExpertProfile = !!sessionStorage.getItem("hasExpertProfile");
  const redirectUrl = foward.getFowardUrl();

  if (!user) {
    foward.setUrl(rest.location.pathname);
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!user ? (
          !!redirectUrl ? (
            foward.goFoward()
          ) : (
            <RouteComponent {...routeProps} />
          )
        ) : (
          <Redirect
            to={
              hasExpertProfile
                ? constants.urls.expert.signin.url
                : constants.urls.subscriber.signin.url
            }
          />
        )
      }
    />
  );
};

export default Component;
