import { useEffect, useState } from "react";
import Alert from "../../../../components/Alert";
import Grid from "../../../../components/Grid";
import Members from "../../components/MembersList";
import { connect, useDispatch } from "react-redux";
import InviteMembers from "../../components/MembersList/components/InviteMembers";
import {
  getGroupById,
  getGroupSubscribers,
} from "../../../../store/actions/group";
import Button from '../../../../components/Button'
import Input from "../../../../components/FormikForm/Input";
import ReactSelect from "../../../../components/FormikForm/ReactSelect";
import Submit from "../../../../components/FormikForm/Submit";
import Tabs from "../components/Tabs";
import { Filters, FilterLabel } from "./styles";
import { Formik } from "formik";
import {
  initialValues,
  schema,
} from "../../../../constants/forms/subscribers-filters";
import { Group } from "../../../../types";
import { useLayout } from "../../../../providers/LayoutProvider";

interface Filter {
  [field: string]: (string | number)[];
}

interface ComponentProps {
  match;
  group: Group;
  groupSubscribersInformation;
  groupSubscribersStates;
}

const Component = ({
  match,
  group,
  groupSubscribersInformation,
  groupSubscribersStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [isOpened, setIsOpened] = useState(false);


  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [match]);

  const getSubscribres = () => {
    dispatch(getGroupSubscribers({ id: group?.id, page, filters }));
  };

  const paginate = (page) => {
    setPage(page);
  };

  const applyFilters = ({ values, actions }) => {
    setFilters(values);
  };

  useEffect(() => {
    getSubscribres();
  }, [filters, page, group]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: `Listado de miembros de tu membresía ${group?.group_name}.`,
        back: `/dashboard/${group?.group_type}/${group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "members",
          component: [
            <Tabs
              active="members"
              hiddenTabs={group?.recorded ? ["surveys", 'library', "configuration"] : ['modules']}
            />,
          ],
        },
      },
      header: {
        visible: true,
        actions: [<Button
          onClick={() => {
            setIsOpened(true)
          }}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Agregar Miembros
        </Button>]
      },
    });
  }, [group, isOpened]);


  return (
    <>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Alert
            visible={true}
            title="Aumenta el numero de suscriptores!"
            text="No olvides promocionar tu grupo compartiendo el Link en tus redes sociales."
            id="aumenta-tus-suscriptores"
          />
        </Grid.Col>
      </Grid.Row>

      <div style={{ height: 30 }} />

      <Grid.Row>
        <Grid.Col>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              applyFilters({ values, actions });
            }}
            validateOnChange={true}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={handleSubmit}
                  id="subscribers-list-filters"
                >
                  <Filters>
                    <FilterLabel>Filtrar por:</FilterLabel>

                    <Input
                      name="email"
                      touched={touched["email"]}
                      error={errors["email"]}
                      value={values["email"]}
                      type="string"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <ReactSelect
                      name="status"
                      touched={touched["status"]}
                      error={errors["status"]}
                      placeholder="Estado"
                      isClearable
                      isMulti
                      items={[
                        { value: "active", label: "Activo" },
                        { value: "cancelled", label: "Cancelado" },
                        { value: "non_renewing", label: "Proximo a vencer" },
                        { value: "expired", label: "Invitado Expirado" },
                        { value: "pending_invite", label: "Invitado Pendiente" },
                        { value: "refunded", label: "Reembolsado" },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <Submit
                      isSubmmiting={groupSubscribersStates.loading}
                      form="subscribers-list-filters"
                      options={{
                        type: "filled",
                        skin: "purple",
                        size: "lg",
                      }}
                    >
                      Buscar
                    </Submit>
                  </Filters>

                  {/* <AutoSave /> */}
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Members
            states={groupSubscribersStates}
            data={groupSubscribersInformation?.data}
            filters={filters}
            pagination={groupSubscribersInformation?.pagination}
            goToPage={paginate}
            groupId={group?.id}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>

    <InviteMembers isOpened={isOpened} onClose={() => setIsOpened(false)}/>
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: groupSubscribersInformation, states: groupSubscribersStates } =
    groupStore.groupSubscribers;

  return {
    group,
    groupSubscribersInformation,
    groupSubscribersStates,
  };
};

export default connect(state)(Component);
