import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  background: #FFFFFF;
  border: 1px solid #6C5CE7;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 29px;
  padding: 0px 16px 0px 21px;

  button, a {
    display: block;
    background: none;
    border: 0px;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #6C5CE7;
    cursor: pointer;
    text-decoration: none;
    
    &:disabled {
      color: gray;
    }
  }
`
