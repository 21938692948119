import * as Yup from "yup";

export const schema = Yup.object().shape({
  primary_color: Yup.string().nullable(),
  secondary_color: Yup.string().nullable(),
});

interface FormValues {
  primary_color: string
  secondary_color: string
}

export const initialValues: FormValues = {
  primary_color: '',
  secondary_color: '',
};
