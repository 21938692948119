import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { CurrencyLabel, GrayBox } from "./styles"
import Input from "../../../../../../../components/FormikForm/Input";
import ReactSelect from "../../../../../../../components/FormikForm/ReactSelect"
import Checkbox from "../../../../../../../components/FormikForm/Checkbox";
import Radio from "../../../../../../../components/FormikForm/Radio";
import Image from "../../../../../../../components/Image"
import flagArgentina from "../../../../../../../constants/countries/flags/flag-AR.png"
import flagUnitedStates from "../../../../../../../constants/countries/flags/flag-US.png"
import flagEuropeanUnion from "../../../../../../../constants/countries/flags/flag-UE.png"
import { Group } from "../../../../../../../types";
import {Title} from "../EditPlanForm/styles";
import {formatPrice} from "../../../../../../../helpers/price-formatter";

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

const periodsUnits = [
  { value: "month", label: "Mensual" },
  { value: "quarter", label: "Trimestral" },
  { value: "half-year", label: "Semestral" },
  { value: "year", label: "Anual" }
]

const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

const billingPeriods = [
  {
    label: "Pago único",
    value: "charge",
  },
  {
    label: "Pago recurrente ",
    value: "subscription",
  },
]

const currencyLabel = (currency) => {
  return (
    <CurrencyLabel>
      <Image src={currency?.flag} width="28px" height="20px" alt="" />
      {currency.description}
    </CurrencyLabel>
  )
}

interface ComponentProps {
  groupInformation: Group,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading: boolean,
  isNew?: boolean,
  isEdit?: boolean,
}

const Component = ({
                     groupInformation,
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     setFieldValue,
                     setFieldError,
                     setFieldTouched,
                     isLoading,
                     isNew,
                     isEdit,
                   }: ComponentProps) => {
  const [planType, setPlanType] = useState(groupInformation?.group_type === "membership" ? "subscription" : "charge");
  const [billingCycleType, setBillingCycleType] = useState(groupInformation?.group_type === "membership" ? "cobro-indefinido" : "cobro-tiempo-limitado");

  useEffect(() => {
    if (values["type"])
      setPlanType(values["type"])

    if (values["billing_cycle_type"])
      setBillingCycleType(values["billing_cycle_type"])
  }, [values]);

  const selectedPeriod = useMemo(() => {
    return periodsUnits?.find(period => period?.value === (values["period_unit"]))
  }, [values])

  const periodsUnitsTranslated = useMemo(() => {
    if (values["billing_cycles"] && values["period_unit"] && selectedPeriod) {
      const cyclesSelected = values["billing_cycles"];
      const selectedPeriodUnit = periodsUnitsTranslations[selectedPeriod?.value]
      return selectedPeriodUnit[cyclesSelected > 1 ? "plural" : "singular"]
    }
  }, [values, selectedPeriod])

  const paymentText = useMemo(() => {
    if (values["billing_cycles"] && values["period_unit"] && selectedPeriod && periodsUnitsTranslated) {
      const billingCyclesSelected = values["billing_cycle_type"]
      const priceSelected = values["price"]
      const cyclesSelected = values["billing_cycles"];
      const currencySelected = values["currency"]
      const selectedPeriodUnit = periodsUnitsTranslations[selectedPeriod?.value]
      const selectedPeriodText = values["period"] > 1 ? `cada ${values?.period} ${selectedPeriodUnit["plural"]}` : `por ${selectedPeriodUnit["singular"]}`;
      const applyToOldSubscribers = values["apply_new_price"]

      const startText = `Se cobrara <strong><u>$${formatPrice(Number(priceSelected) || 0)} ${currencySelected}</u></strong>`;
      const endText = applyToOldSubscribers.includes("true") ? "Se aplicara a nuevos y viejos suscriptores." : "Se aplicara solo a nuevos suscriptores.";

      if (values["type"] === "charge") {
        return `${startText} en un <strong><u>único pago</u></strong>. ${isEdit ? endText : ""}`
      } else {
        if (groupInformation.group_type === "membership") {
          return `${startText} ${selectedPeriodText} por tiempo <strong><u>indefinido</u></strong>. ${isEdit ? endText : ""}`
        } else {
          return `${startText} ${selectedPeriodText}. Se realizaran en total <strong><u>${cyclesSelected} cobros</u></strong> al cabo de <strong><u>${cyclesSelected * values['period']} ${selectedPeriodUnit["plural"]}</strong></u> ${isEdit ? endText : ""}`
        }
      }
    } else {
      return "Termina de configurar el precio."
    }
  }, [values, selectedPeriod, periodsUnitsTranslated])

  const billingCycles = useMemo(() => {
    return [
      {
        label: "Cobrar de forma indefinida",
        value: "cobro-indefinido",
      },
      {
        label: `Cantidad de cobros personalizado`,
        value: "cobro-tiempo-limitado",
      },
    ]
  }, [selectedPeriod])

  return (
    <>
      <Input
        name="external_name"
        error={errors["external_name"]}
        touched={touched["external_name"]}
        value={values["external_name"]}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder={`✨ Nombre del precio`}
        options={{
          label: "Nombre",
          helperText: "Este será el nombre visible del precio en la página de ventas. ¡Puedes usar emojis 🙌 🧘🏼!",
          marginBottom: 20,
        }}
        disabled={isLoading}
      />

      <ReactSelect
        name="currency"
        error={errors["currency"]}
        touched={touched["currency"]}
        items={currencies.map(currency => ({ value: currency?.iso, label: currencyLabel(currency) }))}
        defaultValue={values["currency"]}
        onChange={handleChange}
        onBlur={handleBlur}
        options={{
          label: "Elige la moneda",
          marginBottom: 20,
          loading: isLoading,
        }}
        disabled={isLoading}
      />

      <Radio
        name="type"
        error={errors["type"]}
        touched={touched["type"]}
        value={values["type"]}
        onChange={handleChange}
        onBlur={handleBlur}
        items={billingPeriods}
        options={{
          marginBottom: 20,
          label: "Forma de pago",
          vertical: true,
          helperText: " La forma de pago recurrente representa un débito automático y el pago único representa 1 solo cobro."
        }}
        disabled={isLoading}
      />

      {planType === "subscription" && (
        <ReactSelect
          name="period_unit"
          error={errors["period_unit"]}
          touched={touched["period_unit"]}
          items={periodsUnits}
          defaultValue={values["period_unit"]}
          onChange={handleChange}
          onBlur={handleBlur}
          options={{
            label: "Periodo de pago",
            marginBottom: 20,
            helperText: "Especifica cada cuanto tiempo debe realizarse cada cobro."
          }}
          disabled={values["type"] === "charge" || isLoading}
        />)}

      {groupInformation?.group_type === 'experience' && planType === "subscription" && (
        <>
          {/*<Radio
            name="billing_cycle_type"
            error={errors["billing_cycle_type"]}
            touched={touched["billing_cycle_type"]}
            value={values["billing_cycle_type"]}
            onChange={handleChange}
            onBlur={handleBlur}
            items={billingCycles}
            options={{
              marginBottom: 20,
              label: "Cantidad de cobros",
              vertical: true,
              helperText: "Configura cuantas veces se les cobrará a tus suscriptores."

            }}
            disabled={values["type"] === "charge" || isLoading}
          />*/}

          <Input
            name="billing_cycles"
            error={errors["billing_cycles"]}
            touched={touched["billing_cycles"]}
            value={values["billing_cycles"]}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            placeholder="Cantidad"
            options={{
              label: "Elíge la cantidad de cobros a realizar",
              helperText: "Indica cuantos cobros totales deben realizarse en total",
              marginBottom: 20,
              after: "Cobros"
            }}
            disabled={values["type"] === "charge" || isLoading}
          />
        </>
      )}

      <Input
        name="price"
        error={errors["price"]}
        touched={touched["price"]}
        value={values["price"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="0"
        type="number"
        options={{
          label: "Precio",
          marginBottom: 20,
          before: values["currency"]
        }}
        disabled={isLoading}
      />

      {values['price'] && (
        <GrayBox>
          <Title>Así es cómo quedará el Precio:</Title>
          <div dangerouslySetInnerHTML={{ __html: paymentText }} />
        </GrayBox>
      )}
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
