import { createRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import GoToSubscribeSection from '../GoToSubscribeButton'
import Description from "../Description";
import { Page } from "../../../components/styles";
import SubscriptionCard from "../SubscriptionCard";
import Benefits from "../Benefits";
import Content from "../Content";
import Testimonials from "../Testimonials";
import ItsForMe from "../ItsForMe";
import RegistrationClosingDate from '../RegistrationClosingDate'
import HowItsWorks from "../HowItsWorks";
import Faqs from "../Faqs";
import { Center } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import Grid from "../../../../../components/Grid";
import SubscriptionCta from "../SubscriptionCta";
import { Separator } from "../../../../../components/StyledComponents";
import { isSubscribed } from "../../../../../helpers/isSubscribed";


interface ComponentProps {
  match;
  suscriptions;
  group: Group;
}

const Component = ({
  match,
  suscriptions,
  group,
}: ComponentProps) => {
  const [hideStickySubscriptionCard, setHideStickySubscriptionCard] = useState<{
    aboveFold: boolean;
    belowFold: boolean;
  }>();

  const scrollToRef = createRef<HTMLDivElement>()

  return (
    <Page>
      <SubscriptionCta />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Description />

            <Separator height={{ desktop: "28px", mobile: "28px" }} />

            <Center horizontal="center" vertical="center" column>
              {!isSubscribed(suscriptions, group?.id) && (
                <GoToSubscribeSection scrollToRef={scrollToRef} style={{ width: "260px" }} />
              )}
              <Separator height={{ desktop: "32px", mobile: "16px" }} />
              <RegistrationClosingDate />
            </Center>

            <Separator height={{ desktop: "68px", mobile: "34px" }} />

            <Benefits />

            <Separator height={{ desktop: "90px", mobile: "90px" }} />

            {!isSubscribed(suscriptions, group?.id) && (
              <div ref={scrollToRef}>
                <SubscriptionCard callback={setHideStickySubscriptionCard} />
              </div>
            )}

            <Content />

            {!!group?.reviews && group?.reviews?.length > 0 && (
              <>
                <Separator height={{ desktop: "120px", mobile: "90px" }} />
                <Testimonials />
              </>
            )}

            {!!group?.this_is_for_me && (
              <>
                <Separator height={{ desktop: "120px", mobile: "90px" }} />
                <ItsForMe />
              </>
            )}

            <Separator height={{ desktop: "120px", mobile: "90px" }} />

            {group?.expert?.id !== 2551 && group?.expert?.id !== 10195 && (
              <>
                <HowItsWorks />
                <Separator height={{ desktop: "120px", mobile: "90px" }} />
              </>
            )}

            <Faqs />
            <Separator height={{ desktop: "20px", mobilep: "20px" }} />

            <SubscriptionCard
              sticky
              scrollToRef={scrollToRef}
              belowFold={hideStickySubscriptionCard?.belowFold}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Page>
  );
};

const state = ({ userStore, groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: suscriptions } = userStore.subscriptions;

  return {
    suscriptions,
    group,
  };
};

export default connect(state)(Component);
