import { useMemo, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { Input } from "./styles";
import { MaterialPicker } from 'react-color'
import { Classes } from "reactcss";
import EyeOpen from "./../../../assets/icons/eye-on.svg";
import EyeClose from "./../../../assets/icons/eye-off.svg";

import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  FieldAfter,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
  TooglePassword,
  CharacterCounter,
} from "../Field";
import { CSSProperties } from "@remirror/theme";
import { useFormikContext, FormikValues } from "formik";
import { MaterialPickerStylesProps } from "react-color/lib/components/material/Material";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  error?: {};
  touched?: {};
  step?: string;
  placeholder?: string;
  style?: CSSProperties;
  onFocus?: (data: any) => void;
  onBlur?: (event: any, callback?: any) => void;
  onChange?: (data: any) => void;
  min?: string | number;
  max?: string | number;
  autocomplete?: "off" | "on" | "new-password";
  forwardRef?;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
  };
}

const Component = ({
  forwardRef,
  options = {},
  type,
  disabled,
  name,
  error,
  touched,
  max,
  onFocus,
  onBlur,
  onChange,
  style,
  ...rest
}: ComponentProps) => {
  const formikContext = useFormikContext<FormikValues>();
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled
  );
  const { skin, size, marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };
  const [fieldType, setFieldType] = useState(type);
  const [numCharacters, setNumCharacters] = useState(0);

  const changeColor = (colorSelected) => {
    if (!!colorSelected && !!name) {
      const color = `${colorSelected.rgb.r},${colorSelected.rgb.g},${colorSelected.rgb.b}`;
      const { setFieldValue } = formikContext;
      console.log(name, colorSelected, color)
      setFieldValue(name, color);
    }
  };

  const fieldColor = useMemo(() => {
    if (rest?.value) {
      const color = rest?.value.split(',');

      return { r: Number(color[0]), g: Number(color[1]), b: Number(color[2]), a: 1 }
    }

    return { r: 0, g: 0, b: 0, a: 1 }
  }, [rest?.value])


  const handleOnChange = (event, callback) => {
    const { value } = event.target;
    setNumCharacters(value.length);
    callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event,);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  const customStyles: Partial<Classes<MaterialPickerStylesProps>> = {
    default: {
      material: {
        position: 'relative',
        zIndex: 2,
        height: 'auto',
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        backgroundColor: 'white'
      },
    }
  }

  return (
    <FieldContainer
      marginBottom={marginBottom}
      style={{ ...style, ...(hidden && { display: "none" }) }}
    >
      {options?.label && (
        <Label
          size={options?.size}
          htmlFor={`input-${name}`}
          style={!!options?.explainText ? { marginBottom: "0px" } : {}}
        >
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      {!!options?.explainText && (
        <ExplainText>{options?.explainText}</ExplainText>
      )}

      <Field skin={skin} size={size} className={classes} style={{ justifyContent: 'flex-start', height: 127, border: 0, padding: 0, boxShadow: 'none' }}>
        {options?.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        <MaterialPicker
          color={fieldColor}
          onChange={(color, event) => {
            console.log('Material Picker', color, event)
            changeColor(color)
          }}
          styles={customStyles}
        />

        <div style={{
          backgroundColor: `rgb(${rest?.value})`, width: '100%', height: '100%', position: 'absolute',
          zIndex: 1
        }} />

        <input
          id={name}
          name={name}
          type="hidden"
          disabled={disabled}
          ref={forwardRef}
          onChange={(event) => handleOnChange(event, onChange)}
          onBlur={(event) => handleOnBlur(event, onBlur)}
          onFocus={(event) => handleOnFocus(event, onFocus)}
          onWheel={(event) => event.currentTarget.blur()}
          {...rest}
        />

        {(options?.after || type === "password") && (
          <FieldAfter size={options?.size} className="field-after">
            {options && options.after}
          </FieldAfter>
        )}
      </Field>

      {((options && options.helperText) || max || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
          {max && (
            <CharacterCounter errors={!!error && !!touched}>
              {numCharacters} / {max}
            </CharacterCounter>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
