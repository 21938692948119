import { SAVE_USER_SUBSCRIPTION } from "../types/user/subscription";

export const saveUserSubscription = (data: {
  reference_id?: string;
  platform?: string;
  isFree?: boolean;
  groupId?: number;
  groupSlug?: string;
  referral_id: string | null;
  organization_id?: string | null;
  name?: string
  email?: string
}) => {
  return { type: SAVE_USER_SUBSCRIPTION, payload: data };
};

export const saveUserFreeSubscription = (groupId) => {
  return { type: SAVE_USER_SUBSCRIPTION, payload: groupId };
};
