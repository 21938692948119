import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
// import Happiness from "../components/ModalHappiness";
import { SectionTitle } from './styles'
import Grid from "../../../components/Grid";
import Tabs from "../components/Tabs";
import { useHistory } from "react-router";
import {
  Title,
  RainbowSectionName,
  Separator,
} from "../../../components/StyledComponents";
import LastActivity from "./components/LastActivity";
import FeaturedContent from "./components/FeaturedContent";
import Loader from "../../../components/LogoLoader";
import TelegramBanner from "./components/TelegramBanner";
import EmptyState from "../components/EmptyState";
import WelcomeSection from "../components/WelcomeSection";
import { Page } from "../components/styles";

import {
  getGroupBySlug,
  getGroupLastActivity,
} from "../../../store/actions/group";
import {
  getFeaturedPosts,
  getPosts,
  resetGetPost,
} from "../../../store/actions/post";

import { Post } from "../../../types/post";
import { Group, SessionInDto, Subscription, User } from "../../../types";

import NoSubscribed from "../components/NoSubscribed";
import { Layout, useLayout } from "../../../providers/LayoutProvider";

import GoToTelegram from "../components/GoToTelegram";
import { isSubscribed } from "../../../helpers/isSubscribed";
import NextSessions from "./components/NextSessions";
import { getAllSessions } from "../../../store/actions/session";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import { Redirect } from "react-router-dom";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";
import { GetWelcomeContentSectionInDto } from "../../../types/welcome-section";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { getUserPreferences } from "../../../store/actions/preferences";
import RecommendedActions from "./components/RecommendedActions";
import LastSessions from "./components/LastSessions";

const groupTypes = {
  membership: "Membresía",
  experience: "Taller",
  free_experience: "Evento Gratuito",
  community: "Comunidad",
};

interface ComponentProps {
  isLoggedIn: boolean;
  user: User;
  userStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  welcomeContentSection: GetWelcomeContentSectionInDto
  welcomeContentSectionStates
  subscriptions: Subscription[];
  subscriptionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  sessions: SessionInDto[];
  sessionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  lastActivities;
  lastActivitiesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  posts: { pagination: {}, data: Post[] };
  featuredPosts: { pagination: {}, data: Post[] };
  featuredPostsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
  userPreferences: {
    id: number
    userId: string
    userEmail: string
    preferenceSlug: string
    preferenceValue: string
  }[]
}

const Component = ({
  isLoggedIn,
  user,
  userStates,
  welcomeContentSection,
  welcomeContentSectionStates,
  subscriptions,
  subscriptionsStates,
  posts,
  group,
  groupStates,
  match,
  sessions,
  sessionsStates,
  lastActivities,
  lastActivitiesStates,
  featuredPosts,
  featuredPostsStates,
  userPreferences
}: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch();
  const [showEmptyState, setShowEmptyState] = useState<boolean>();
  const { setLayout, layout } = useLayout();
  const history = useHistory();

  const showMinimized = useMemo(() => {
    if (!!userPreferences && !!group) {
      const slug = `minimize-welcome-section-${group?.welcome_section}`
      return userPreferences?.find(preference => preference.preferenceSlug === slug)
    }
  }, [userPreferences, group])

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      body: {
        visible: true,
      },
    }),
    [layout, subscriptions, group, user]
  );

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])

  useEffect(() => {
    dispatch(getUserPreferences())
  }, [])

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, group, user]);

  useEffect(() => {
    if (
      !!match?.params && !groupStates.loading &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group))
    ) {
      dispatch(resetGetPost());
      match.params && dispatch(getGroupBySlug(match?.params?.group));
    }
  }, [match]);

  useEffect(() => {
    // if (
    //   !!group &&
    //   (!posts?.data ||
    //     (!!posts?.data && posts?.data?.length > 0 &&
    //       typeof posts?.data[0].group === "number" &&
    //       posts?.data[0].group !== group?.id))
    // ) {
    //   dispatch(getPosts({ pagination: { page: 1, per_parge: 6, _sort: 'desc', _sort_by: 'published_at' }, filters: { group_id: group?.id } }));
    // }

    if (!!group && group?.id !== match?.params?.group && !lastActivities && !lastActivitiesStates.loading) {
      match.params && dispatch(getGroupLastActivity({ group_id: group?.id }));
    }

    if (!!group && group?.id !== match?.params?.group && !sessions && !sessionsStates.loading) {
      match.params && dispatch(getAllSessions({ idGroup: group?.id }));
    }

    if (!!group && group?.id !== match?.params?.group && !featuredPosts && !featuredPostsStates.loading) {
      dispatch(
        getFeaturedPosts({
          pagination: { page: 1, per_parge: 6, _sort: 'asc', _sort_by: 'pinned_order' },
          filters: { group_id: group?.id, show_scheduled_post: true }
        })
      );
    }
  }, [group, match]);

  let previousPath = '';

  useEffect(() => {
    return history.listen(location => {
      previousPath = location.pathname;
    })
  }, [history]);

  useEffect(() => {
    if (previousPath === '/survey') {
      history.push('/profile/subscriptions');
    }
  }, [previousPath]);

  useEffect(() => {
    const featuredPostsIsEmpty = !featuredPosts || featuredPosts?.data?.length <= 0;
    const sessionsIsEmpty = !sessions || sessions.length <= 0;
    const lastActivityIsEmpty = !lastActivities || lastActivities.length <= 0;
    const welcomeSectionIsEmpty = !welcomeContentSection || (!!welcomeContentSection && welcomeContentSection?.welcome_contents?.length <= 0);


    setShowEmptyState(
      featuredPostsIsEmpty && sessionsIsEmpty && lastActivityIsEmpty && welcomeSectionIsEmpty
    );
  }, [
    featuredPosts,
    featuredPostsStates,
    sessions,
    sessionsStates,
    lastActivities,
    lastActivitiesStates
  ]);

  if (
    groupStates.loading ||
    sessionsStates.loading ||
    lastActivitiesStates.loading ||
    featuredPostsStates.loading ||
    userStates.loading ||
    subscriptionsStates.loading
  ) {
    return <Loader />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Page>
        <Helmet>
          ‍<title></title>‍
          <meta name="description" content="" />
        </Helmet>


        {/* <Separator height={{ desktop: "63px", mobile: "40px" }} />

            <RainbowSectionName>
              {!!group?.expert?.showable_name &&
                `${groupTypes[group?.group_type]} de ${group?.expert?.showable_name
                }
                `}
            </RainbowSectionName>

            <Title>{group?.group_name}</Title>

            <Separator height={{ desktop: "40px", mobile: "16px" }} />

            <Tabs
              active="activity-summary"
              hiddenTabs={group?.recorded ? ["activity-summary", "library"] : group?.group_type === 'free_experience' ? ['modules', "activity-summary"] : ['modules']}
            />

            <Separator height={{ desktop: "70px", mobile: "32px" }} /> */}

        {isLoggedIn &&
          (isSubscribed(subscriptions, group?.id) ||
            isOwnGroup({
              group,
              expertId: user?.expert?.id,
            })) && (
            <Grid.Container>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}><TelegramBanner /></Grid.Col>
              </Grid.Row>
            </Grid.Container>
          )}

        {!showEmptyState && (
          <Grid.Container>
            <Grid.Row>
              <Grid.Col lg={10} offset={{ lg: 1 }}>
                <NextSessions />
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        )}

        {!showMinimized && (isSubscribed(subscriptions, group?.id) ||
          isOwnGroup({
            group,
            expertId: user?.expert?.id,
          })) && (
            <WelcomeSection />
          )}

        {!!isMobile && (
          <Grid.Container>
            <Grid.Row>
              <Grid.Col lg={10} offset={{ lg: 1 }}>
                <RecommendedActions />
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        )}

        {!showEmptyState && (
          <>
            <Grid.Container>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  <FeaturedContent />
                  <LastActivity />
                  {/* <LastSessions /> */}
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>

          </>
        )}

        {showMinimized && (isSubscribed(subscriptions, group?.id) ||
          isOwnGroup({
            group,
            expertId: user?.expert?.id,
          })) && (
            <Grid.Container>
              <Grid.Row>
                <Grid.Col lg={10} offset={{ lg: 1 }}>
                  {!welcomeContentSectionStates.loading && (
                    <>
                      <div style={{ height: 26 }} />
                      {!!welcomeContentSection?.user_finished_welcome_section ? (
                        <SectionTitle>Revive tu bienvenida {!isMobile && `a ${group?.title}`}</SectionTitle>
                      ) : (
                        <SectionTitle>Tus primeros pasos {!isMobile && `a ${group?.title}`}</SectionTitle>
                      )}
                      <div style={{ height: 26 }} />
                    </>
                  )}
                  <WelcomeSection minimized={true} />
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          )
        }

        {showEmptyState && (
          <Grid.Container>
            <Grid.Row>
              <Grid.Col lg={10} offset={{ lg: 1 }}>
                <EmptyState text={
                  <>
                    <strong>¡Aún no hay actividad!</strong>
                    <br /> Aguarda a que se comience cargar contenido.
                  </>
                } />
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        )}
      </Page>

      {/* <NoSubscribed /> */}
    </>
  )
};

const state = ({ userStore, postStore, groupStore, sessionStore, welcomeContentStore, preferencesStore }) => {
  const { isLoggedIn } = userStore;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: sessions, states: sessionsStates } = sessionStore.all;
  const { data: lastActivities, states: lastActivitiesStates } =
    groupStore.lastActivity;
  const { data: featuredPosts, states: featuredPostsStates } =
    postStore.featuredPosts;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;
  const { data: userPreferences } = preferencesStore.preferences

  return {
    lastActivities,
    lastActivitiesStates,
    subscriptions,
    subscriptionsStates,
    isLoggedIn,
    user,
    userStates,
    posts,
    postsStates,
    group,
    groupStates,
    sessions,
    sessionsStates,
    featuredPosts,
    featuredPostsStates,
    welcomeContentSection,
    welcomeContentSectionStates,
    userPreferences
  };
};

export default connect(state)(Component);
