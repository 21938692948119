import { connect, useDispatch } from "react-redux";
import { showModal } from "./../../../../../store/actions/modal";
import { Data, Button, UserEmail, Section, SelectedColorShape, ImageCard } from "../../styles";
import ModalChangeWhiteLabel from "./components/ModalChangeWhiteLabel";
import logoBig from "../../../../../assets/logo.svg";
import logoSmall from "../../../../../assets/logo-icon.svg";
import EditSidebar from "./components/EditSidebar";

const Component = ({ userData }) => {
  const dispatch = useDispatch();
  return (
    <Section>
      <Data>Marca blanca</Data>

      <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginBottom: "16px" }}>
        Logo Grande
        <ImageCard src={userData?.expert?.big_logo?.url || logoBig} />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginBottom: "16px" }}>
        Logo Chico
        <ImageCard src={userData?.expert?.small_logo?.url || logoSmall} />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginBottom: "16px" }}>
        Color Primario
        <SelectedColorShape color={`rgba(${userData?.expert?.primary_color || '108, 92, 231'}, 1)`} />
        {`rgba(${userData?.expert?.primary_color || '108, 92, 231'}, 1)`}
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginBottom: "16px" }}>
        Color Secundario
        <SelectedColorShape color={`rgba(${userData?.expert?.secondary_color || '108, 92, 231'}, 1)`} />
        {`rgba(${userData?.expert?.secondary_color || '108, 92, 231'}, 1)`}
      </div>

      <Button
        type="button"
        onClick={() => dispatch(showModal("change-white-label-sidebar"))}
        loading={false}
        options={{
          skin: "purple",
          size: "lg",
          type: "link",
        }}
      >
        Modificar
      </Button>
      <EditSidebar onClose={() => { }} />
    </Section>
  );
};

const state = ({ userStore }) => {
  const { information } = userStore;

  const { data: userData, states: userStates } = information;

  return {
    userData,
    userStates,
  };
};

export default connect(state)(Component);
