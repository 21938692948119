import styled from "styled-components";

export const CarouselContent = styled("div") <{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 1px 14px rgb(0 0 0 / 7%);
  padding: 15px 0px;
  flex-direction: column;
  gap: 15px;
${({ type }) => { console.log('TYPEEEE', type); return '' }}
  ${({ type }) => type === 'Telegram' ?
    `background: rgb(57,183,246); background: linear-gradient(90deg, rgba(57,183,246,1) 0%, rgba(147,219,255,1) 100%);` :
    `background: rgb(255,255,255); background: linear-gradient(61deg, rgba(30,190,165,1) 0%,  rgba(104,231,176,1) 75%, rgba(229,255,204,1) 99%);`
  }

  

  @media (min-width: 1024px) {
    flex-direction: row;
    height: 82px;
    padding: 0px;
    gap: 0px;
  }

  .carousel__inner-slide {
    display: flex;
  }
`;

export const Step = styled("div") <{ color: string }>`
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 150px;
  height: 75px;
  flex-shrink: 0;
  position: relative;
  
  @media (min-width: 1024px) {
    width: 120px;
    height: auto;
  }
  
  i {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: ${({ color }) => color};
    position: absolute;
    top: 0%;
    right: 0px;
    transform: translate(0%, -50%);
    z-index: 0;

    @media (min-width: 1024px) {
      top: 50%;
    }
  }
`;

export const SlideContent = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 21px;
  width: 100%;
  padding: 0px 45px 0px 25px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0px 45px 0px 25px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 30px;
  margin-left: 24px;
  text-align: center;
  margin-bottom: 26px;

  @media (min-width: 1024px) {
    display: block;
    min-height: 0;
    text-align: left;
    margin-bottom: 0px;
  }

  @media (min-width: 1200px) {
    margin-right: 80px;
  }

  br {
    @media (max-width: 1439px) {
      display: none;
    }
  }
`;

export const Actions = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  width: 100%;

  & > * {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (min-width: 1024px) {
      width: auto;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    width: auto;
  }
`;
