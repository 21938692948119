import { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik, FormikHelpers } from 'formik';
import { MaterialPicker } from 'react-color'
import ColorPicker from '../../../../../../../components/FormikForm/ColorPicker'
import Sidebar from "../../../../../../../components/Sidebar";
import Button from "../../../../../../../components/Button";
import File from "../../../../../../../components/FormikForm/File";
import Input from "../../../../../../../components/FormikForm/Input";
import TextArea from '../../../../../../../components/FormikForm/TextArea';
import Submit from "../../../../../../../components/FormikForm/Submit";
import Snackbar from "../../../../../../../components/Snackbar";
import { initialValues, schema } from '../../../../../../../constants/forms/white-label/edit'
import { updateCategory } from '../../../../../../../store/actions/category'
import { deleteFile } from '../../../../../../../store/actions/post';
import { resetUpdateWhiteLabel, updateWhiteLabel } from '../../../../../../../store/actions/user';

interface ComponentProps {
  expert
  updateWhiteLabelStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string
  }
  onClose: any
  visibleModal: string
}

const Component = ({
  expert,
  updateWhiteLabelStates,
  onClose,
  visibleModal
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [actions, setActions] = useState<FormikHelpers<any>>();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    setActions(actions)
    dispatch(updateWhiteLabel(values));
  };

  const whiteLabel = useMemo(() => ({
    primary_color: expert?.primary_color,
    secondary_color: expert?.secondary_color,
    big_logo: expert?.big_logo ? expert?.big_logo : [],
    small_logo: expert?.small_logo ? expert?.small_logo : []
  }), [expert])

  useEffect(() => {
    if (updateWhiteLabelStates.success) {
      !!onClose && onClose();

      setTimeout(() => {
        actions && actions?.resetForm()
        dispatch(resetUpdateWhiteLabel());
      }, 3500);
    }
  }, [updateWhiteLabelStates]);

  return <>
    <Sidebar
      position="right"
      visible={visibleModal === 'change-white-label-sidebar'}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Editar marca blanca"
    >
      <Formik
        initialValues={{
          ...initialValues,
          ...whiteLabel,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}

        validateOnChange={false}
        validateOnBlur={formSubmmited}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          console.log(errors)
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="change-white-label-form"
            >
              <ColorPicker
                name="primary_color"
                error={errors["primary_color"]}
                touched={touched["primary_color"]}
                value={values["primary_color"]}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Color primario"
                options={{
                  label: "Color primario",
                  marginBottom: 40,
                }}
              />

              <ColorPicker
                name="secondary_color"
                error={errors["secondary_color"]}
                touched={touched["secondary_color"]}
                value={values["secondary_color"]}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Color secundario"
                options={{
                  label: "Color secundario",
                  marginBottom: 40,
                }}
              />

              <File
                name="big_logo"
                error={errors["big_logo"]}
                touched={touched["big_logo"]}
                value={values["big_logo"]}
                rules={{
                  maxFiles: 1,
                  typeFiles: ["image"],
                }}
                onRemoveItem={(event, file) => {
                  if (file?.id) {
                    dispatch(deleteFile({ idFile: file?.id }));
                  }
                }}
                options={{
                  label: "Logo grande",
                  explainText:
                    "Puedes agregar tu logo para la versión de escritorio de la plataforma. Debe ser un archivo JPG o PNG de hasta 20 MB. El tamaño ideal es de 128 px (ancho) x 55 px (alto), pero si subes uno de otro tamaño, también funcionará.",
                  marginBottom: 40,
                  showLoadFileCta: false,
                }}
              />

              <File
                name="small_logo"
                error={errors["small_logo"]}
                touched={touched["small_logo"]}
                value={values["small_logo"]}
                rules={{
                  maxFiles: 1,
                  typeFiles: ["image"],
                }}
                onRemoveItem={(event, file) => {
                  if (file?.id) {
                    dispatch(deleteFile({ idFile: file?.id }));
                  }
                }}
                options={{
                  label: "Logo pequeño",
                  explainText:
                    "Puedes agregar tu logo para la versión móvil de la plataforma. Debe ser un archivo JPG o PNG de hasta 20 MB. El tamaño ideal es de 333 px (ancho) x 174 px (alto), pero si subes uno de otro tamaño, también funcionará.",
                  marginBottom: 40,
                  showLoadFileCta: false,
                }}
              />
            </form>
          );
        }}
      </Formik>

      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Button
          disabled={updateWhiteLabelStates.loading}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
          onClick={() => onClose()}
        >
          Cancelar
        </Button>

        <Submit
          isSubmmiting={updateWhiteLabelStates.loading}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
          form="change-white-label-form"
        >
          Actualizar
        </Submit>
      </div>
    </Sidebar>

    <Snackbar
      visible={updateWhiteLabelStates.success || !!updateWhiteLabelStates.error}
      options={{
        time: 2000,
        type: updateWhiteLabelStates.success ? "success" : "error",
      }}
    >
      {updateWhiteLabelStates.success
        ? "Marca blanca actualizada."
        : "Error al actualizar la marca blanca."}
    </Snackbar>
  </>
}

const state = ({ userStore, modalStore }) => {
  const { data: user } = userStore.information
  const { states: updateWhiteLabelStates } = userStore.changeWhiteLabel
  const { visibleModal } = modalStore

  return {
    expert: user?.expert,
    updateWhiteLabelStates,
    visibleModal
  };
};

export default connect(state)(Component);