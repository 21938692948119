const key = "KLOUSER_FORWARD_URL";

const forward = {
  setUrl: (url = window.location.href): void => {
    localStorage.setItem(key, url);
  },
  getFowardUrl: (): string | null => {
    return localStorage.getItem(key);
  },
  goFoward: (): void => {
    const url = localStorage.getItem(key);
    if (url) {
      localStorage.removeItem(key);
      window.location.href = url;
    }
  },
};

export default forward;
